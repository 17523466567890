import React from 'react';
import './Rating.css';
import feedbackSOVTraining from '../../../data/feedbackSOVTraining'
import feedbackPercorso from '../../../data/feedbackPercorso';
import feedbackWebinar from '../../../data/feedbackWebinar';
import feedbackPalpazione from '../../../data/feedbackPalpazione';
import CourseRating  from '../CourseRating/CourseRating';
import PropTypes from 'prop-types';

const Rating = (props) => {
	var feedbackSource = "";
	if (props.tipologia == "SOVTraining Online")
		feedbackSource = feedbackSOVTraining;
	else if (props.tipologia == "Percorso Formativo Voce")
		feedbackSource = feedbackPercorso;
	else if (props.tipologia == "Webinar Gratuito")
		feedbackSource = feedbackWebinar;
	else if (props.tipologia == "Palpazione")
		feedbackSource = feedbackPalpazione;
	else 
		feedbackSource = "";

	var divStyle = {
		background: "#bcb",
		padding: "20px",
		margin: "20px",
		borderRadius: 9
	  };

	const renderElements = () => {
		if (feedbackSource == "")
			return;

		const gridElements = feedbackSource.details.map( (element, i) => {
		  return (
			<div key={i} style={divStyle}>
				<h4>{element.recensione}</h4>
				<CourseRating vote_average={Number(element.rating)} 
						multiplier="1"
						max="5"
						label="📊 Valutazione"
						optimum='5'
						low="2"
						high="4"
					/>
				<CourseRating vote_average={Number(element.docenti)} 
						multiplier="1"
						max="5"
						label="👩‍🏫 Docenti"
						optimum='5'
						low="2"
						high="4"
					/>
				<CourseRating vote_average={Number(element.organizzazione)} 
						multiplier="1"
						max="5"
						label="🎫 Organizzazione"
						optimum='5'
						low="2"
						high="4"
					/>
				<br></br>	
				<h4>👨‍🎓 <i>Corsista {element.evento}</i></h4>				
			</div>
		  )
		})
		return gridElements;
	  }

  return (
				<div className="rating-main">
				{
					feedbackSource.rating !== undefined && feedbackSource.rating >= 0 ||
					feedbackSource.ratingDocenti !== undefined && feedbackSource.ratingDocenti >= 0 || 
					feedbackSource.ratingOrganizzazione !== undefined && feedbackSource.ratingOrganizzazione >= 0 ?
					<p><h1>Valutazioni dei corsisti per {feedbackSource.nome}</h1>
					<h4>Numero di Valutazioni dei Corsisti: {feedbackSource.numeroRecensioni}</h4></p> 
					: ""	
				}

				{ feedbackSource.rating !== undefined && feedbackSource.rating >= 0?
					<CourseRating vote_average={Number(Math.round(feedbackSource.rating * 100)/100)} 
						multiplier="1"
						max="5"
						label="📊 Valutazione Corso"
						optimum='5'
						low="2"
						high="4"
					/>
					: "" }

				{ feedbackSource.ratingDocenti !== undefined && feedbackSource.ratingDocenti >= 0 ? 	
					<CourseRating vote_average={Number(Math.round(feedbackSource.ratingDocenti * 100)/100)} 
						multiplier="1"
						max="5"
						label="👩‍🏫 Docenti"
						optimum="5"
						low="2"
						high="4"
					/>
				: "" }

				{ feedbackSource.ratingOrganizzazione !== undefined && feedbackSource.ratingOrganizzazione >= 0 ? 
					<CourseRating vote_average={Number(Math.round(feedbackSource.ratingOrganizzazione * 100)/100)} 
						multiplier="1"
						max="5"
						label="🎫 Organizzazione Corso"
						optimum="5"
						low="2"
						high="4"
					/> 
				: "" }


<br></br>
<br></br>
       	<a href="https://bit.ly/buonavoce" className="am-link btn btn-primary btn-action ga" data-ga-action="click" data-ga-label="free-trial" data-qa="link-free-trial" target="info">Richiedi Brochure dei Corsi e disponibilità posti</a>
		<div className="buonavoce-grid-content">
        {renderElements()}
		</div>
	</div>
	
)
}

Rating.propTypes = {
	tipologia: PropTypes.string,
}
export default Rating;