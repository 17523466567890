import React from 'react';
import PropTypes from 'prop-types';
//import './MovieThumb.css';

// const CourseRating = (props) => {
//   return (
//     <div className="buonavoce-rating">
//       <h3>Valutazione dei corsisti</h3><div className="buonavoce-rating"><meter min="0" max="100" optimum="100" low="40" high="70" value={props.vote_average * 10}></meter><p className="buonavoce-score">{props.vote_average}</p></div>
//     </div>
//   )
// }

// CourseRating.propTypes = {
//   vote_average: PropTypes.number
// }

// export default CourseRating;


// import PropTypes from 'prop-types';
// //import './MovieThumb.css';

const CourseRating = (props) => {
  return (
    <div className="buonavoce-rating">
      <h3>{props.label}</h3><div className="buonavoce-rating"><meter min="0" max={props.max} optimum={props.optimum} low={props.low} high={props.high} value={props.vote_average * props.multiplier}></meter><p className="buonavoce-score">{props.vote_average}</p></div>
    </div>
  )
}

CourseRating.propTypes = {
  vote_average: PropTypes.number,
  label: PropTypes.string,
  max: PropTypes.string,
  optimum: PropTypes.string,
  low: PropTypes.string,
  high: PropTypes.string
  ,multiplier: PropTypes.string
}

export default CourseRating;
