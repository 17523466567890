import React, { Component } from 'react';
import { API_URL, API_KEY } from '../../config';
import Navigation from '../elements/Navigation/Navigation';
import MovieInfo from '../elements/MovieInfo/MovieInfo';
import MovieInfoBar from '../elements/MovieInfoBar/MovieInfoBar';
import FourColGrid from '../elements/FourColGrid/FourColGrid';
import Actor from '../elements/Actor/Actor';
import Spinner from '../elements/Spinner/Spinner';
import './Corso.css';
import Rating from '../elements/Rating/Rating'


const Corso = (props) => {

    return (
      <div className="buonavoce-movie">
        {//this.state.movie ?
          props.corso ? 
          <div>
            <MovieInfo movie={props.corso} directors={props.directors} />
            <MovieInfoBar 
            time={props.corso.runtime} 
            budget={props.corso.budget} 
            costo={props.corso.costo} 
            location={props.corso.location} 
            ecm={props.corso.ecm} 
            trailer={props.corso.trailer}
            />
          </div>
        : null }
        { props.corso !== undefined && props.corso.docente ?    //props.movie.docente
          <div className="buonavoce-movie-grid">
            <FourColGrid header={props.corso.docente.lenght > 1 ? 'Docenti' : 'Docenti'} type="DocentiDelCorso" loading={props.loading}>
              {props.corso.docente.map( (element, i) => {
                return <Actor key={i} docente={element} />
              })}
            </FourColGrid >
          </div>
          : null }
          {/* {props.loading ? <Spinner /> : null} */}
          <div id="feedbackCorsisti" className="buonavoce-rating-text">
            { props.corso !== undefined && props.corso.tipologia !== undefined && (props.corso.tipologia).length > 0 ?
              <Rating tipologia={props.corso.tipologia} /> : ""
            } 
          </div>
      </div>
    )
}

export default Corso;