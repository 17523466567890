import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>Whoooops. This page doesn't exist</h1>
    </div>
  )
}

export default NotFound;