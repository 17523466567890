import React, { Component } from 'react';
import { API_URL, API_KEY, IMAGE_BASE_URL, POSTER_SIZE, BACKDROP_SIZE} from '../../config';
import HeroImage from '../elements/HeroImage/HeroImage';
import Footer from '../elements/Footer/Footer';

import './Aboutus.css';

const IMAGE_DEFAULT_URL = './images/corsi/';

const Aboutus = (props) => {
    return (
      <div id="about-us-page" role="main" className="page-main" data-tracking-category="about-us-page">
          <div className="container main-content-area">
            <div className="row">
                <div className="col-xs-12 col-md-8">
                    <br></br><br></br><br></br>
                    <h1>La nostra missione</h1>
                    <p>Per aiutarti ad apprendere le competenze necessarie per raggiungere il tuo pieno potenziale formativo.</p>
                    <h1>Chi siamo</h1>
                    <p>Buona Voce Formazione s.a.s. è una società in continua evoluzione che propone progetti formativi in ambito sanitario e professionale, permettendo ai propri corsisti di acquisire specifiche ed elevate competenze per raggiungere obiettivi personali, professionali e lavorativi.<br></br>I nostri corsisti hanno la possibilità di scegliere tra un'ampia gamma di corsi presenziali ed online coinvolgenti e di alta qualità tenuti da esperti del settore riconosciuti.</p>
                    <p>Il progetto Buona Voce nasce nel 2010 e dal 2017 Buona Voce ha organizzato eventi sul territorio nazionale, corsi residenziali ed in diretta online, seminari e congressi per oltre 500 professionisti e studenti.</p>
                    <h1 className="section-header">A chi è rivolto Buona Voce Formazione?</h1>
                    <p>Oltre ad aiutare a trovare il percorso formativo più adatto per i professionisti, medici, terapisti ed artisti, Buona Voce è da sempre particolarmente sensibile alle esigenze formative degli studenti ai quali vengono riservate tariffe agevolate e percorsi formativi specifici al fine di raggiungere obiettivi personali e professionali nella maniera più efficace possibile.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-8">
                    <h1>Orari di Segreteria</h1>
                    <p>Dal Lunedì al Venerdì:<br></br>9:00 - 13:00<br></br>15:00 - 18:00<br></br><a href="mailto:info@buonavoce.it">info@buonavoce.it</a><br></br></p>
                </div>
            </div>
        </div>
        <Footer></Footer>
      </div>
      
    )
  }
//}

export default Aboutus;

//{(this.state.currentPage <= this.state.totalPages && !this.state.loading) ?  <LoadMoreBtn text="Mostrane altri" onClick={this.loadMoreItems} />  : null }