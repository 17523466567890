import React from 'react';
import FontAwesome from 'react-fontawesome';
import { calcTime, convertMoney, strCompare, IntValueOrZero } from '../../../helpers.js';
import './MovieInfoBar.css';

const MovieInfoBar = (props) => {
  return (
    <div className="buonavoce-movieinfobar">
      <div className="buonavoce-movieinfobar-content">
        <div className="buonavoce-movieinfobar-content-col">
          <FontAwesome className="fa-time" name="clock-o" size="2x" />
          <span className="buonavoce-movieinfobar-info">Durata: {IntValueOrZero(props.time) > 0 ? calcTime(props.time) : 'Da Stabilire'}</span>
        </div>
        <div className="buonavoce-movieinfobar-content-col white">
          <a href="https://bit.ly/buonavoce" target="buy" className="white">
          <FontAwesome className="fa-revenue white" name="ticket" size="2x"  />
          <span className="buonavoce-movieinfobar-info white">Costo: {IntValueOrZero(props.costo) > -1 ? convertMoney(props.costo) : 'Da Stabilire'}</span>
          </a>
        </div>
        <div className="buonavoce-movieinfobar-content-col">
          <FontAwesome className="fa-budget" name={strCompare(props.location,"Online") ? "laptop" : "map-marker" } size="2x" />
          <span className="buonavoce-movieinfobar-info">{strCompare(props.location,"Online") ? "Online in Diretta" : props.location}</span>
        </div>
        <div className="buonavoce-movieinfobar-content-col">
          <FontAwesome className="fa-budget" name="money" size="2x" />
          <span className="buonavoce-movieinfobar-info">ECM: {IntValueOrZero(props.ecm) > 0 ? props.ecm : IntValueOrZero(props.ecm) < 0 ? 'Richiesto Accreditamento' : 'Non previsti'}</span>
        </div>
      </div>
    </div>
  )
}

export default MovieInfoBar;