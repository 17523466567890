const quotes = [
    {
        "quote": "Il Corso, esaustivo e approfondito, necessita comunque di approfondimento individuale, di studio e di pratica.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "E' un corso bellissimo e molto interessante, con esposizione chiara, appassionante ed appassionata.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Ottimo corso, condotto in maniera egregia. Nonostante non fosse un corso in presenza fisica, è stato possibile usufruire in maniera chiara ed esaustiva degli argomenti trattati.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Corso ben organizzato . Aspetto la proposta di altri corsi di formazione online a cui partecipare.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Me pareció muy bien planteado, temas bien expuestos, cordialidad de los profesionales.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Corso ottimo a livello pratico oltre che teorico.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "E' stato davvero formativo ed esaustivo riguardo all'argomento trattato. E' stato molto bello anche il fatto che ci fossero sia logopedisti sia cantanti. a me è servito.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Grazie mille, avevo proprio bisogno di fare chiarezza sull'argomento.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Corso veramente stimolante. Ricco di spunti di riflessione, soprattutto nella modalità di approccio ai pazienti. Vi ringrazio veramente per la vostra disponibilità e professionalità.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Davvero interessante! argomenti nuovi sul panorama della voce!",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "E' la seconda volta che partecipo a questo corso. Ho partecipato in passato alla primissima edizione dal vivo. Nonostante fosse online ho trovato un miglioramento riguardo l'organizzazione del corso, argomenti più esaustivi e momenti più strutturati e mai dispersivi. Funzionava già bene la prima volta ma adesso direi che il corso è davvero ottimo da tutti i punti di vista.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Molto efficace l'idea di far provare i vari esercizi ai partecipanti durante la spiegazione.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Formazione estremamente chiara e puntuale, relatori molto preparati e appassionati. Contenuti interessanti e stimolanti per il taglio pratico e fruibile da più professionisti.",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Professionalità e chiarezza al massimo livello, complimenti!",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Continuate così, veramente fatto bene!!! Vittoria Carlino è molto gentile e ipercompetente, la adoro.",
        "author": "Corsisti SOVTraining Online"
    },
    {
        "quote": "Docenti preparati e disponibili. Esposizione degli argomenti chiara e lineare, approfondita ma senza sbavature nelle tempistiche. Buona l'interazione con i partecipanti. Bella l'idea di proporre in prima giornata gli esercizi, rimandando alla seconda giornata la valutazione logopedica. Molto utile il role play guidato (e di durata adeguata).",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "È stato bello essere parte di questa cosa, anche per la vostra 'parte umana' che non era per nulla scontata è che è stata indubbiamente un valore aggiunto. GRAZIE ❤️",
        "author": "Corsista SOVTraining Online"
    },
    {
        "quote": "Volevo farvi i complimenti: è uno dei pochi webinar gratuiti ad aver fornito nozioni utili e nuove ai partecipanti.",
        "author": "Partecipante Webinar SOVTraining Gratuito (Aprile 2020)"
    },
    {
        "quote": "Molto utile ed esaustivo, l'interattività è stata ottima.",
        "author": "Corsista Percorso Formativo (Novembre-Dicembre 2020)"
    },
    {
        "quote": "Mi è sembrato veramente cucito su misura per noi neolaureate! Perfetto per chi vuole entrare nel mondo della riabilitazione delle disfonie con professionalità ma anche semplicità. Grazie di cuore!!",
        "author": "Corsista Percorso Formativo (Novembre-Dicembre 2020)"
    },
    {
        "quote": "Entusiasmante anche per chi è alle prime armi con la voce e ha voglia di formazione. Docenti sempre pronti a sostenere l'apprendimento con ripetizioni ed esempi. Coinvolgimento di noi partecipanti sempre presente. Lo consiglierò a chiunque volesse approcciarsi al mondo voce, così come alcune colleghe lo hanno consigliato a me.",
        "author": "Corsista Percorso Formativo (Novembre-Dicembre 2020)"
    },
    {
        "quote": "Giusto bilanciamento tra inquadramento teorico e approccio pratico.",
        "author": "Corsista Percorso Formativo (Novembre-Dicembre 2020)"
    },
    {
        "quote": "Il corso è stato molto utile e interattivo. Ho apprezzato molto il fatto che ci sia stata la possibilità di fare ipotesi sia sulla parte valutativa sia su quella riabilitativa. Mi ha aiutato molto nel ragionamento clinico.",
        "author": "Corsista Percorso Formativo (Novembre-Dicembre 2020)"
    },
    {
        "quote": "Da neolaureata, ho apprezzato tantissimo i preziosi consigli che mi sono stati forniti durante il corso. Inoltre, ritengo che tutte le nozioni apprese saranno sicuramente un tesoro da custodire durante la mia carriera lavorativa.",
        "author": "Corsista Percorso Formativo (Novembre-Dicembre 2020)"
    },
    {
        "quote": "In sole poche giornate ho appreso davvero tanto, ho le idee più chiare sia su valutazione sia su trattamento e molte informazioni in più sull'igiene vocale: ringrazio davvero i docenti per l'impegno.",
        "author": "Corsista Percorso Formativo (Gennaio 2021)"
    },
    {
        "quote": "Ho apprezzato molto questo corso perchè mi ha dato strumenti e basi teoriche utili per approcciarmi ai disturbi vocali in modo critico e molto più organizzato di come avrei fatto senza.",
        "author": "Corsista Percorso Formativo (Gennaio 2021)"
    },
    {
        "quote": "Da studentessa posso dire che il corso è stato veramente interessante e soprattutto sia teorico che pratico, cosa che in tantissimi corsi (soprattutto se online), viene a mancare la seconda.",
        "author": "Corsista Percorso Formativo (Gennaio 2021)"
    },
    {
        "quote": "È stato un corso veramente molto valido, mi piace molto l'associazione e trovo i docenti dei corsi proposti sempre molto validi, professionali e preparati, seguirei molti altri corsi in programma se non avessi i limiti di tempo dovuti al mio lavoro, ciò nonostante preferisco comunque la modalità sincrona rispetto a quella asincrona perché considero la possibilità di avere un confronto diretto con i docenti un valore aggiunto e altamente formativo.",
        "author": "Corsista Percorso Formativo (Gennaio 2021)"
    },
    {
        "quote": "Ogni corso organizzato da Buonavoce è sempre una garanzia. Sempre ottimi relatori, la dott.ssa Carlino una moderatrice d'eccellenza ed il supporto informatico sempre preciso, efficace ed efficiente. Ogni corso proposto è un'esperienza formativa unica ed arricchente.",
        "author": "Corsista Balbuzie (Marzo 2021)"
    },
    {
        "quote": "Un'ottima esperienza. Mi ha aiutato a sfatare dei miti, a modificare il mio punto di vista e soprattutto a strutturare un percorso chiaro nel caso di una presa in carico. Ricco di spunti!!",
        "author": "Corsista Balbuzie (Marzo 2021)"
    },
    {
        "quote": "Ho apprezzato molto la professionalità della docenza, la chiarezza dell'esposizione di contenuti non così semplici nonostante la modalità a distanza",
        "author": "Corsista Funzioni Esecutive (Marzo 2021)"
    },
    {
        "quote": "Federica è una Logopedista davvero straordinaria, la sua passione per ciò che svolge quotidianamente si percepisce immediatamente. Ho apprezzato molto gli esempi pratici e la disponibilità di condividere le conoscenze!",
        "author": "Corsista Funzioni Esecutive (Marzo 2021)"
    },
    {
        "quote": "Bellissimo corso, l'ho apprezzato moltissimo. Docenti attenti, disponibili, professionali e competenti.",
        "author": "Corsista SOVTraining Online (Aprile 2021)"
    },
    {
        "quote": "Era un approfondimento che volevo fare da tempo. Trovo che questo argomento in particolare sia assolutamente fruibile anche da remoto e mi sono trovato davvero bene. Ringrazio di cuore i docenti che ci hanno accompagnato in maniera impeccabile durante questo week end. Alla prossima occasione, che sono certo non mancherà!",
        "author": "Corsista SOVTraining Online (Aprile 2021)"
    },
    {
        "quote": "E' stato tutto chiarissimo, ben organizzato anche nella divisione delle tematiche, i riferimenti continui tramite foto e file, professionalità eccellente di tutti! grazie grazie grazie",
        "author": "Corsista Percorso Formativo (Maggio-Giugno 2021)"
    },
    {
        "quote": "Il corso è andato oltre ogni aspettativa... avevo molti dubbi sulla formazione a distanza ma per questo corso mi sono dovuta ricredere",
        "author": "Corsista Percorso Formativo (Maggio-Giugno 2021)"
    },
    {
        "quote": "Come tutti i corsi di Buonavoce, è stato ricco di informazioni e spunti pratici. Sempre il top!",
        "author": "Corsista SOVTraining Online (Ottobre 2021)"
    },
    {
        "quote": "Il corso è veramente molto utile ad approfondire l'argomento sovte, è proprio quello che speravo di trovare sul tema",
        "author": "Corsista SOVTraining Online (Ottobre 2021)"
    },
    {
        "quote": "Ottimo su ogni livello",
        "author": "Corsista SOVTraining Online (Ottobre 2021)"
    },
    {
        "quote": "Programma ben strutturato e chiaro. Fondamentale riferimento a EBM. Chiari i protocolli. Disponibilità e chiarezza dei docenti.",
        "author": "Corsista SOVTraining Online (Dicembre 2021)"
    },
    {
        "quote": "Corso molto interessante ",
        "author": "Corsista SOVTraining Online (Dicembre 2021)"
    },
    {
        "quote": "Splendido corso!",
        "author": "Corsista SOVTraining Online (Dicembre 2021)"
    },
    {
        "quote": "Corso interessante ed entusiasmante! Le ore trascorse insieme sono volate e le conoscenze trasmesse sono state davvero tante. Adesso non mi resta che continuare a sperimentare ed approfondire. Grazie Buona Voce!",
        "author": "Corsista SOVTraining Online (Dicembre 2021)"
    },
    {
        "quote": "Vi ringrazio per aver saputo trasmettere con veramente molta chiarezza l'argomento 'sovte'. Ho molto apprezzato l'alternarsi della parte teorica in cui veniva spiegato il razionale degli esercizi a quella pratica in cui noi partecipanti siamo stati guidati alla loro corretta esecuzione. Nonostante il corso fosse online, sento veramente di avere acquisito nuovi e utili strumenti per la pratica logopedica! È un super corso!",
        "author": "Corsista SOVTraining Online (Dicembre 2021)"
    },
    {
        "quote": "Mi piacerebbe ripeterlo dal vivo",
        "author": "Corsista SOVTraining Online (Dicembre 2021)"
    },
    {
        "quote": "Ringrazio di cuore che esistano corsi simili e professionisti che lavorano con questa passione, capaci di trasmettere ed avere sempre un atteggiamento di accoglienza verso chi si approccia al mondo della voce. Grazie!",
        "author": "Corsista Percorso Formativo (Dicembre 2021)"
    },
    {
        "quote": "Docenti speciali, unici, umani e di grande esperienza: un corso di altissimo livello",
        "author": "Corsista SOVTraining Online (Febbraio 2022)"
    },
    {
        "quote": "Proporrei un follow up con un ulteriore approfondimento di quanto appreso e feedback su come sia stato utilizzato in pratica",
        "author": "Corsista SOVTraining Online (Febbraio 2022)"
    },
    {
        "quote": "Da formatore vi faccio i miei complimenti per tutto. siete stati una splendida scoperta",
        "author": "Corsista SOVTraining Online (Febbraio 2022)"
    },

    



    
]

export default quotes;