import React, { Component } from 'react';
import { API_URL, API_KEY } from '../../config';
import Navigation from '../elements/Navigation/Navigation';
import FourColGrid from '../elements/FourColGrid/FourColGrid';
import Actor from '../elements/Actor/Actor';
import Spinner from '../elements/Spinner/Spinner';
import './Docenti.css';


//import Data from '../../../public/data/docenti.json';
import Footer from '../elements/Footer/Footer';


class Docenti extends Component {
state = {
  docenti: [],
  loading: true
}
/*
componentWillMount(){
  this.setState({
    docenti: Data.docenti
  });
}
*/
//https://api.themoviedb.org/3/movie/553604?api_key=844dba0bfd8f3a4f3799f6130ef9e335&language=it-IT
  componentDidMount() {
    /*if (localStorage.getItem(`${this.props.match.params.corsoId}`)) {
      const state = JSON.parse(localStorage.getItem(`${this.props.match.params.corsoId}`));
      this.setState({ ...state });
    } else */
    {
      this.setState({ loading: true })
      // First fetch the movie ...
      const endpoint =  `${API_URL}/data/docenti.json`; //`${API_URL}movie/${this.props.match.params.corsoId}?api_key=${API_KEY}&language=en-US`;
      this.fetchItems(endpoint);
    }
  }

  fetchItems = (endpoint) => {
    fetch(endpoint)
    .then(result => result.json())
    .then(result => {
      console.log
      if (result.status_code) {
        this.setState({ loading: false });
      } else {
        this.setState({ corsi: result }, () => {
          // ... then fetch actors in the setState callback function
          //https://api.themoviedb.org/3/movie/553604/credits?api_key=844dba0bfd8f3a4f3799f6130ef9e335&language=it-IT
          const endpoint = `${API_URL}/data/docenti.json`; //DettagliCorsi; //`${API_URL}movie/${this.props.match.params.corsoId}/credits?api_key=${API_KEY}`;
          fetch(endpoint)
          .then(result => result.json())
          .then(result => {
            this.setState({
              docenti: result.docenti,
              loading: false
            }
            /*, () => {
              localStorage.setItem(`${this.props.match.params.corsoId}`, JSON.stringify(this.state));
            }*/)
          })
        })
      }
    })
    .catch(error => console.error('Error:', error))
  }

render() {  
    return (
      <div className="buonavoce-home-grid">
          <div className="buonavoce-movie-grid-DOC">
            <br></br>
            <FourColGrid header={'I Nostri Formatori'} >
              { this.state.docenti.map ( (element, i) => {
                return <Actor key={i} docente={element} />
              })}
            </FourColGrid >
          </div>
          <Footer />
      </div>
    );

  }
}

export default Docenti;