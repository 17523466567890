import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './FourColGrid.css';

const FourColGrid = (props) => {

  const renderElements = () => {
    const gridElements = props.children.map( (element, i) => {
      return (
        <div key={i} className="buonavoce-grid-element">
            {element}
        </div>
      )
    })
    return gridElements;
  }

  return (
   <div> 
    {props.type === "DocentiDelCorso" ? 
    <div className="buonavoce-grid-DocentiDelCorso whiteStatic">
      {props.header && !props.loading ? <h1>{props.header}</h1> : null}
      <div className="buonavoce-grid-content">
        {renderElements()}
      </div>
    </div>
    :
    <div className="buonavoce-grid whiteStatic">
      {props.header && !props.loading ? <div><h1>{props.header}</h1>{props.subtitle && !props.loading ?<h2>{props.subtitle}</h2> : null}</div> : null}
      <div className="buonavoce-grid-content">
        {renderElements()}
      </div>
    </div>
    }
    </div>
  )
}

FourColGrid.propTypes = {
  header: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  type: PropTypes.string
}

export default FourColGrid;