import React from "react";
import "../homeContent/videoAdDiv.css";

function VideoAdDiv() {
  return (
    <div className="videoAdDiv">
      <div className="videoDiv">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/C4yRUF0WVgY?autoplay=1&mute=0&enablejsapi=1" title="Buona Voce Formazione - SOVTraining Online - Trailer" frameborder="0" allow="autoplay;" allowfullscreen></iframe>
        <br></br>
        <center><a href="https://bit.ly/buonavoce" className="am-link btn btn-primary btn-action ga" data-ga-action="click" data-ga-label="free-trial" data-qa="link-free-trial" target="info">Richiedi info Corsi</a></center>
      </div>
      <div className="content">
        <h2 className="heading">SOVTraining è un progetto innovativo di lavoro e sperimentazione sulla voce</h2>
        <p className="about">
        è rivolto a tutti coloro che desiderano approfondire la conoscenza della voce attraverso un nuovo punto di vista e una nuova prospettiva di lavoro.<br></br><u>Cantanti, insegnanti di canto, logopedisti, medici, insegnanti di scuola, attori e doppiatori</u> avranno l’<b>occasione di lavorare direttamente su questi esercizi</b>, imparando a conoscerli e ad applicarli
        </p>
      </div>
    </div>
  );
}

export default VideoAdDiv;
