const feedbackSOVTraining = {
    "nome": "SOVTraining Online",
    "numeroRecensioni": "259",
    "details": [
      {
        "id": 1,
        "recensione": "Programma ben strutturato e chiaro. Fondamentale riferimento a EBM. Chiari i protocolli. Disponibilità e chiarezza dei docenti.",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 2,
        "recensione": "Vi ringrazio per aver saputo trasmettere con veramente molta chiarezza l'argomento SOVTE. Ho molto apprezzato l'alternarsi della parte teorica in cui veniva spiegato il razionale degli esercizi a quella pratica in cui noi partecipanti siamo stati guidati",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 3,
        "recensione": "Il corso è veramente molto utile ad approfondire l'argomento sovte, è proprio quello che speravo di trovare sul tema ",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "È stato fondamentale per l'approfondimento dei sovte",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 4,
        "recensione": "Era un approfondimento che volevo fare da tempo. Trovo che questo argomento in particolare sia assolutamente fruibile anche da remoto e mi sono trovato davvero bene. Ringrazio di cuore i docenti che ci hanno accompagnato in maniera impeccabile durante que",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 5,
        "recensione": "Docenti speciali, unici, umani e di grande esperienza: un corso di altissimo livello",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 6,
        "recensione": "Come tutti i corsi di Buonavoce, è stato ricco di informazioni e spunti pratici. Sempre il top!",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 7,
        "recensione": "Bellissimo corso, l'ho apprezzato moltissimo. Docenti attenti, disponibili, professionali e competenti.",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 8,
        "recensione": "è un corso così interessante che per me potrebbe anche durare 3 giorni",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 9,
        "recensione": "Corso interessante ed entusiasmante! Le ore trascorse insieme sono volate e le conoscenze trasmesse sono state davvero tante. Adesso non mi resta che continuare a sperimentare ed approfondire. Grazie Buona Voce!",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 10,
        "recensione": "Sono globalmente entusiasta. L'argomento è stato di mio interesse e lo sto già utilizzando nella mia pratica",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 11,
        "recensione": "Proporrei un follow up con unlteriore approfondimento di quanto appreso e feedback su come sia stato utilizzato in pratica",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 12,
        "recensione": "Mi sono sentita un po' penalizzata dalla modalità online, la connessione è saltata più volte o comunque spesso i video dei docenti si bloccavano e non riuscivo a seguire bene gli esercizi, probabilmente a causa della mia connessione. ",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "3",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 13,
        "recensione": "corso molto interessante ",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 14,
        "recensione": "Splendido corso!",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 15,
        "recensione": "Mi piacerebbe ripeterlo dal vivo",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 16,
        "recensione": "Nessun suggerimento, tutto perfetto.",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 17,
        "recensione": "Ottimo su ogni livello",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 18,
        "recensione": "suggerimento di tipo organizzativo: mi piacerebbe molto ricevere i materiali di studio prima dell'inizio del corso in modo da poterlo integrare con gli appunti ",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 19,
        "recensione": "Di iniziare almeno mezz'ora dopo la domenica, alle 14:00 stavo imboccando l'ultimo boccone di pasta",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 20,
        "recensione": "Possibilità anche per i non logopedisti o medici di approfondire alcuni aspetti e problemi logopedici",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 21,
        "recensione": "Creare un livello 2 ",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 22,
        "recensione": "fate quello che dite di fare, complimenti!",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 23,
        "recensione": "Mi ha aperto tante porte dal punto di vista tecnico e psicologico . Ha sollecitato le componenti emotive e le componenti tecniche del mio corpo , fornendomi un approccio di riguardo e attenzione verso le mie sensazioni.",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 24,
        "recensione": "magari un'oretta in più per le classi canto e logopedia; allargherei anche a studenti osteopatia ed osteopati",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 25,
        "recensione": "Mi è piaciuto TUTTO !  Il programma è stato essenziale e allo stesso tempo esaustivo. L'esposizione ottima. Corso organizzato, articolato e gestito in maniera impeccabile.",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 26,
        "recensione": "I tre relatori sono stati magnifici",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 27,
        "recensione": "Ottima esperienza",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 28,
        "recensione": "Ottimo",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 29,
        "recensione": "Affrontare altre tematiche inerenti patologie della voce",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 30,
        "recensione": "Cambiate piattaforma",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 31,
        "recensione": "Interessante e pieno di spunti per la terapia",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 32,
        "recensione": "Il Corso, esaustivo e approfondito, necessita comunque di approfondimento individuale, di studio e di pratica",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 33,
        "recensione": "corso organizzato e gestito bene",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "La maggior parte delle cose dette al corso le conoscevo già",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 34,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 35,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Conoscevo molti dei contenuti proposti, ma è stato utile riorganizzarli",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 36,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 37,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 38,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 39,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 40,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 41,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 42,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Conoscevo molti dei contenuti proposti, ma è stato utile riorganizzarli",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 43,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 44,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 45,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 46,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 47,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 48,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 49,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 50,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 51,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (5-6 Febbraio 2022)"
      },
      {
        "id": 52,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 53,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 54,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 55,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 56,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 57,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 58,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 59,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 60,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 61,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 62,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 63,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 64,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 65,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (11-12 Dicembre 2021)"
      },
      {
        "id": 66,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 67,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 68,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 69,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 70,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 71,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "4",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 72,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 73,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 74,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 75,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 76,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 77,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 78,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 79,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 80,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 81,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 82,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 83,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 84,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 85,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 86,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "4",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 87,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (9-10 Ottobre 2021)"
      },
      {
        "id": 88,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 89,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 90,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 91,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 92,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 93,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 94,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 95,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 96,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "La maggior parte delle cose dette al corso le conoscevo già",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 97,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 98,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 99,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 100,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 101,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 102,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "La maggior parte delle cose dette al corso le conoscevo già",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 103,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 104,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 105,
        "recensione": "",
        "rating": "5",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 106,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 107,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 108,
        "recensione": "",
        "rating": "5",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 109,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 110,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 111,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 112,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 113,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 114,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 115,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online (10-11 Aprile 2021)"
      },
      {
        "id": 116,
        "recensione": "",
        "rating": "5",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 117,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 118,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 119,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 120,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 121,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 122,
        "recensione": "",
        "rating": "5",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 123,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 124,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 125,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 126,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 127,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 128,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 129,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 130,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (27-28 Febbraio 2021)"
      },
      {
        "id": 131,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 132,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 133,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (17-18 Ottobre 2020)"
      },
      {
        "id": 134,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 135,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 136,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (17-18 Ottobre 2020)"
      },
      {
        "id": 137,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 138,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 139,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (17-18 Ottobre 2020)"
      },
      {
        "id": 140,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Corso complesso per una logopedista che non si occupa di voce cantata",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 141,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (17-18 Ottobre 2020)"
      },
      {
        "id": 142,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 143,
        "recensione": "",
        "rating": "5",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 144,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (17-18 Ottobre 2020)"
      },
      {
        "id": 145,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 146,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 147,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "4",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 148,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 149,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 150,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 151,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 152,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 153,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 154,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 155,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 156,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 157,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 158,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (17-18 Ottobre 2020)"
      },
      {
        "id": 159,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 160,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 161,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (17-18 Ottobre 2020)"
      },
      {
        "id": 162,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 163,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 164,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 165,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "La maggior parte delle cose dette al corso le conoscevo già",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 166,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 167,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 168,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 169,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (17-18 Ottobre 2020)"
      },
      {
        "id": 170,
        "recensione": "",
        "rating": "3",
        "docenti": "4",
        "organizzazione": "3",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 171,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 172,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 173,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 174,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 175,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 176,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 177,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (31 Ottobre - 1 Novembre 2020)"
      },
      {
        "id": 178,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "La maggior parte delle cose dette al corso le conoscevo già",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 179,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 180,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (17-18 Ottobre 2020)"
      },
      {
        "id": 181,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (17-18 Ottobre 2020)"
      },
      {
        "id": 182,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 183,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 184,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "La maggior parte delle cose dette al corso le conoscevo già",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 185,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 186,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 187,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 188,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 189,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 190,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 191,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 192,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 193,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 194,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 195,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "La maggior parte delle cose dette al corso le conoscevo già",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 196,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 197,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 198,
        "recensione": "",
        "rating": "5",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 199,
        "recensione": "",
        "rating": "5",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "La maggior parte delle cose dette al corso le conoscevo già",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 200,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 201,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 202,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 203,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 204,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 205,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 206,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 207,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 208,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 209,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 210,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 211,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 212,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 213,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 214,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 215,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 216,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto utile e bello per come lo avete strutturato! Tante cose le conoscevo perché affrontate nel percorso di studi che ho fatto, ma voi le avete presentate da altre prospettive e non possiamo che ringraziarvi per le vostre ricerche che so",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 217,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 218,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Pur conoscendo già alcune cose dette, per me è stato molto utile l'ordine indicato negli esercizi e la messa in pratica",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 219,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 220,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 221,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 222,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 223,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 224,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 225,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 226,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 227,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 228,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 229,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 230,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 231,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 232,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 233,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 234,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "4",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 235,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 236,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 237,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 238,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 239,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 240,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 241,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 242,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 243,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 244,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 245,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 246,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 247,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 248,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 249,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 250,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 251,
        "recensione": "",
        "rating": "4",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 252,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 253,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 254,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 255,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 256,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 257,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SOVTraining Online Estate 2020"
      },
      {
        "id": 258,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      },
      {
        "id": 259,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "SovTraining Online (14-15 Novembre 2020)"
      }
    ],
    "rating": "4.85714285714286",
    "ratingOrganizzazione": "4.90733590733591",
    "ratingDocenti": "4.9034749034749",
    "numeroVotazioni": "259"
  }


    export default feedbackSOVTraining;