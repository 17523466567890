import React, { Component } from 'react';
import Footer from '../elements/Footer/Footer';


const IMAGE_DEFAULT_URL = './images/corsi/';

const Privacypolicy = (props) => {
    return (
<div>
<div id="about-us-page" role="main" className="page-main" data-tracking-category="about-us-page">
          <div className="container main-content-area">
  
<div className="main-content">
  <div className="container_16">
<div className="grid_12">
<br></br><br></br>
<h1>Buona Voce Formazione Privacy Policy</h1>
<p>Data di entrata in vigore:&nbsp;1 Maggio 2018</p>
</div>
</div>
<div id="grid_12" className="grid_12 copy">
<p>I dati personali forniti o raccolti dai nostri Servizi sono controllati da Buona Voce Formazione s.a.s., Centro Direzionale C2 - 80143 - Napoli - Italia. <strong> In caso di dubbi sulla fornitura di informazioni a noi o sull'utilizzo di tali informazioni in qualsiasi modo consentito dalla presente Informativa sulla privacy, non visitare i nostri siti Web, app o utilizzare in altro modo i nostri Servizi.</strong></p>
<p>La presente Informativa sulla privacy descrive i tipi di informazioni che possiamo raccogliere da te o che potresti fornirci quando visiti i siti web di Buona Voce Formazione o utilizzi i nostri servizi situati in <a href ="https://www.buonavoce.it/"> www.buonavoce.it</a> (i "Siti Web"), o un'applicazione mobile o desktop di Buona Voce Formazione (una "App") (collettivamente, i "Servizi") e le nostre pratiche per la raccolta, l'utilizzo e la manutenzione , proteggere e divulgare tali informazioni. La presente Informativa sulla privacy si applica alle informazioni che raccogliamo: (i) attraverso i nostri Servizi e (ii) nei messaggi di testo ed elettronici tra te ei nostri Servizi.</p>
<p>La presente Informativa sulla privacy non si applica ad altri servizi o alle informazioni raccolte da noi offline o tramite siti di terze parti a cui è possibile accedere tramite collegamenti ipertestuali sui nostri Servizi.</p>
<p>Accedendo o utilizzando i nostri Servizi, accetti la presente Informativa sulla privacy. </p>
<p>SE NON ACCETTI LA PRESENTE POLITICA SULLA PRIVACY, NON ACCEDERE O UTILIZZARE I NOSTRI SERVIZI. </p>
<p>Si prega di leggere le informazioni di seguito: </p>
<ol className="privacy_policy_table_of_contents">
<li> <a href="#Section1"> Informazioni che raccogliamo su di te </a> </li>
<li> <a href="#Section2"> Dove archiviamo i dati </a> </li>
<li> <a href="#Section3"> Come utilizziamo le tue informazioni </a> </li>
<li> <a href="#Section4"> Divulgazione delle tue informazioni </a> </li>
<li> <a href="#Section5"> Aggiornamento o correzione dei dati personali </a> </li>
<li> <a href="#Section6"> Annullamento dell'account o eliminazione dei dati personali </a> </li>
<li> <a href="#Section7"> Scelte riguardanti il controllo dei tuoi dati personali </a> </li>
<li> <a href="#Section8"> Basi legali per l'elaborazione </a> </li>
<li> <a href="#Section9"> Informazioni relative ai dati raccolti tramite il servizio Buona Voce </a> </li>
<li> <a href="#Section10"> Protezione dei dati </a> </li>
<li> <a href="#Section11"> Cookie e altre tecnologie </a> </li>
<li> <a href="#Section12"> Mobile Analytics </a> </li>
<li> <a href="#Section13"> Direct Marketing </a> </li>
<li> <a href="#Section14"> Modifiche a questa Informativa sulla privacy </a> </li>
<li> <a href="#Section15"> Come ci contatti in caso di domande? </a> </li>
</ol>
<h2 id = "Section1"> 1. INFORMAZIONI CHE RACCOGLIAMO SU DI TE </h2>
<p> Raccogliamo diversi tipi di informazioni sui visitatori e / o sugli utenti dei nostri Servizi. Potremmo raccogliere queste informazioni direttamente quando ce le fornisci o automaticamente mentre navighi attraverso i siti web o l'app. </p>
<p> (a) Raccoglieremo ed elaboreremo i tuoi dati personali quando ti registri a un Servizio / crei un account, ti iscrivi a una newsletter, invii feedback, partecipi a un concorso, compili un sondaggio o ci invii una comunicazione. Quando si ordinano prodotti o servizi sui siti Web o sull'app, è possibile che venga richiesto di fornire un numero di carta di credito e altre informazioni relative al pagamento. A seconda dell'attività, alcune delle informazioni che ti chiediamo di fornire sono necessarie per utilizzare i Servizi (ad esempio la registrazione di un account) e alcune sono volontarie. Se non fornisci i dati quando necessario per una particolare attività, non sarai in grado di impegnarti in tale attività. </p>
<p> Raccogliamo anche il tuo indirizzo Internet Protocol ("IP"). Utilizziamo il tuo indirizzo IP per diagnosticare problemi con i nostri server e / o software, per amministrare i nostri Servizi e per raccogliere informazioni demografiche. </p>
<p> (b) Potremmo raccogliere alcune informazioni che di per sé non identificano un individuo specifico. Tali informazioni ci parlano della tua attrezzatura, delle azioni di navigazione e delle risorse a cui accedi e utilizzi tramite i Servizi, come il tuo sistema operativo e il tipo di browser. Usiamo analisi e servizi simili, come spiegato nella nostra <a href="https://www.buonavoce.it/cockie-policy"> Cookie Policy </a>, per aiutarci a fornire una migliore e più personalizzata servizio agli utenti consentendoci di stimare i modelli di utilizzo, personalizzare i nostri Servizi in base alle tue preferenze individuali e velocizzare le tue ricerche. </p>
<p> (c) Quando scarichi la nostra App e utilizzi i nostri Servizi, raccogliamo automaticamente informazioni sul tipo di dispositivo che utilizzi e sulla versione del sistema operativo, quindi conosciamo la migliore versione dell'applicazione da fornirti. Non ti chiediamo, accediamo o tracciamo alcuna informazione basata sulla posizione dal tuo dispositivo mobile in qualsiasi momento durante il download o l'utilizzo della nostra app o dei nostri servizi. </p>
<p> (d) Quando ci contatti per l'assistenza clienti, potremmo raccogliere ulteriori informazioni per risolvere il tuo problema. </p>
<p> (e) <strong> Non siamo un sito destinato ai bambini o ai minori di 16 anni. </strong> Se sei una scuola e utilizzi un servizio Buona Voce per bambini di età inferiore ai 16 anni, o il età minima equivalente nella giurisdizione pertinente, sei responsabile dell'ottenimento del consenso dei genitori / tutori legali di ogni bambino a cui desideri consentire di partecipare all'utilizzo di uno qualsiasi dei nostri Servizi. Un bambino che utilizza i nostri Servizi potrà partecipare ad alcune attività limitate (come seguire corsi educativi e informativi online). </p>
<h2 id = "Sezione2"> 2. DOVE ARCHIVIAMO I DATI </h2>
<p> Inviando i tuoi dati personali, accetti il ​​trasferimento, l'archiviazione e il trattamento dei tuoi dati al di fuori del paese in cui risiedi. Adotteremo misure ragionevoli per garantire che i tuoi dati siano trattati in modo sicuro e in conformità con la presente Informativa sulla privacy. </p>
<h2 id = "Section3"> 3. COME UTILIZZIAMO LE TUE INFORMAZIONI </h2>
<p> Utilizziamo le informazioni su di te nei seguenti modi: </p>
<ul className = "privacy-policy">
<li> Per presentare i contenuti dei nostri Servizi in modo efficace per te e per il tuo computer o dispositivo mobile </li>
<li> Per fornirti informazioni, prodotti o servizi che ci richiedi o che riteniamo possano interessarti </li>
<li> Per adempiere ai nostri obblighi derivanti da eventuali contratti stipulati tra te e noi, inclusi quelli per la fatturazione e l'incasso </li>
<li> Se paghi per i Servizi utilizzando una carta di credito, trasmetteremo i dati della tua carta di credito alla società della carta di credito appropriata e / o alla società di elaborazione dei pagamenti con carta di credito </li>
<li> Per consentirti di partecipare alle funzionalità interattive dei nostri Servizi, quando scegli di farlo </li>
<li> Per contattarti in merito ai nostri Servizi </li>
<li> In qualsiasi altro modo possiamo descrivere quando fornisci le informazioni o quando ti chiediamo in merito a un nuovo utilizzo delle informazioni su di te. </li>
</ul>
<h2 id = "Section4"> 4. DIVULGAZIONE DEI TUOI DATI </h2>
<p> Condivideremo i tuoi dati personali con terze parti solo nei modi descritti nella presente Informativa sulla privacy. Non vendiamo, scambiamo, noleggiamo o divulghiamo le tue informazioni ad altri, ad eccezione di quanto previsto nel presente documento: </p>
<p> (a) Forniamo e supportiamo alcuni dei nostri Servizi tramite accordi contrattuali con fornitori di servizi e altre terze parti. Noi ed i nostri fornitori di servizi utilizziamo i tuoi dati personali per gestire i nostri siti Web e fornire servizi. Ad esempio, dobbiamo rilasciare i dati della tua carta di credito alla banca emittente della carta per confermare il pagamento per prodotti e servizi acquistati sui nostri siti web; e fornire informazioni sull'ordine a terze parti che ci aiutano a fornire i Servizi. </p>
<p> (b) Divulgheremo i dati personali anche nelle seguenti circostanze: (i) se richiesto dalle forze dell'ordine o dalle autorità giudiziarie, o per cooperare con un'indagine delle forze dell'ordine; (ii) se riteniamo in buona fede di essere obbligati o autorizzati a farlo dalla legge o da un procedimento legale; (iii) per proteggere i nostri diritti, reputazione, proprietà o la sicurezza nostra o di altri; (iv) per difendere o far valere i nostri diritti o i tuoi obblighi; (v) se l'informativa è richiesta da standard professionali obbligatori; (vi) a una terza parte con il tuo previo consenso in tal senso; o (vii) se abbiamo l'obbligo di divulgare o condividere i tuoi dati personali al fine di far rispettare o applicare qualsiasi contratto o accordo che potrebbe esistere tra te e noi, inclusa la presente Informativa sulla privacy, il nostro <a href = "https://www.buonavoce.it/policy"> Politica di utilizzo del sito web </a> e i nostri <a href="https://www.buonavoce.it/aboutus/lotterms.aspx"> Termini di servizio </ a>. Ciò include lo scambio di informazioni con altre società e organizzazioni ai fini della protezione dalle frodi e della riduzione del rischio di credito. </p>
<p> (c) Nel caso in cui Buona Voce Formazione decida di vendere tutto o parte dei suoi beni o di stipulare una fusione, ci riserviamo il diritto di includere i tuoi dati, inclusi i dati personali, tra i beni trasferiti all'acquirente o superstite azienda. </p>
<h2 id = "Section5"> 5. AGGIORNAMENTO O CORREZIONE DEI TUOI DATI PERSONALI </h2>
<p> Offriamo impostazioni per controllare e gestire i dati personali che abbiamo su di te, inclusa la possibilità di: </p>
<ul>
<li> <strong> Elimina dati </strong>: puoi chiederci di cancellare o eliminare tutti o alcuni dei tuoi dati personali (ad esempio, se non è più necessario fornirti i Servizi). </li>
<li> <strong> Modifica o rettifica dei dati </strong>: puoi modificare alcuni dei tuoi dati personali tramite il tuo account. Puoi anche chiederci di modificare i tuoi dati in alcuni casi, in particolare se sono imprecisi. </li>
<li> <strong> Opporsi o limitare o limitare l'utilizzo dei dati </strong>: puoi chiederci di interrompere l'utilizzo di tutti o alcuni dei tuoi dati personali (ad esempio, se non abbiamo il diritto legale di continuare a utilizzarli) o per limitare il nostro utilizzo (ad esempio, se i tuoi dati personali sono inesatti o detenuti illegalmente). </li>
<li> <strong> Diritto di accesso e / o acquisizione dei tuoi dati </strong>: puoi chiederci una copia dei tuoi dati personali e puoi chiedere una copia dei dati personali che hai fornito in formato leggibile dalla macchina. </ li >
</ul>
<p> Puoi saperne di più su come effettuare queste richieste nelle sezioni 6 e 7 della presente Informativa sulla privacy. Puoi anche contattarci utilizzando le informazioni di contatto nella Sezione 15 e prenderemo in considerazione la tua richiesta in conformità con le leggi applicabili. </p>
<p> Puoi modificare i tuoi dati personali associati al tuo account nella scheda "Il mio profilo". </p>
<p> Puoi anche accedere, richiedere l'eliminazione e correggere i tuoi dati personali inviando una richiesta di dati tramite <a href="mailto:info@buonavoce.it?Subject=Contatta%20Buona%20Voce"> Assistenza clienti </a> o tramite scrivendoci agli <a href="#Section15"> indirizzi </a> alla fine della presente Informativa sulla privacy. </p>
<h2 id = "Section6"> 6. CANCELLAZIONE DEL TUO ACCOUNT O CANCELLAZIONE DEI TUOI DATI PERSONALI </h2>
<p> Puoi scegliere di lasciare scadere il tuo abbonamento, nel qual caso conserveremo le tue informazioni per un periodo di tempo ragionevole nel caso in cui scegli di iscriverti nuovamente. <a href="https://www.buonavoce.it/aboutus/privacy-policy-learn-more"> Ulteriori informazioni </a>. </p>
<p> Puoi scegliere di chiudere il tuo account, nel qual caso elimineremo le tue informazioni, inclusa la cronologia dell'apprendimento, entro 60 giorni dalla tua richiesta. Tuttavia, potremmo conservare i tuoi dati personali anche dopo che hai chiuso il tuo account se ragionevolmente necessario per adempiere ai nostri obblighi legali (comprese le richieste delle forze dell'ordine), rispettare gli obblighi contrattuali, soddisfare i requisiti normativi , risolvere controversie, mantenere la sicurezza, prevenire frodi e abusi, applicare i nostri <a href="https://www.buonavoce.it/aboutus/lotterms.aspx"> Termini di servizio </a> o <a href = "https://www.buonavoce.it/policy"> Politica di utilizzo del sito web </a>, o soddisfare la tua richiesta di" annullare l'iscrizione "a ulteriori messaggi da parte nostra. Conserveremo le informazioni non personalizzate dopo la chiusura del tuo account. Puoi chiudere il tuo account nella scheda "Il mio profilo". </p>
<p> Se desideri accedere alle informazioni in nostro possesso sul tuo utilizzo e accesso ai Servizi di Buona Voce Formazione, richiedilo tramite la tua scheda “Il mio profilo” o scrivendoci alla <a href = "#Sezione15 "> indirizzi </a> alla fine della presente Informativa sulla privacy. Puoi anche chiederci di smettere di utilizzare tutti o alcuni dei tuoi dati personali (ad esempio, se non abbiamo il diritto legale di continuare a utilizzarli) o di limitarne l'uso (ad esempio, i tuoi dati personali sono inaccurati o detenuti illegalmente) inviando una richiesta di dati tramite <a href="mailto:info@buonavoce.it?Subject=Contatta%20Buona%20Voce"> Customer Support </a> o scrivendoci agli <a href="#Section15"> indirizzi </ a > alla fine della presente Informativa sulla privacy. </p>
<h2 id = "Section7"> 7. SCELTE IN MERITO AL CONTROLLO DEI TUOI DATI PERSONALI </h2>
<p> Puoi accedere ad alcune delle informazioni che raccogliamo su di te. Ad esempio, accedendo al tuo account, puoi accedere alle informazioni relative agli ordini recenti dai Siti Web; alcuni dati personali che conserviamo su di te; le tue preferenze di comunicazione; e le impostazioni di pagamento. </p>
<p> Puoi chiederci una copia dei tuoi dati personali in un formato leggibile dalla macchina nella scheda "Il mio profilo". Se hai domande su questa procedura, contatta l '<a href="mailto:info@buonavoce.it?Subject=Contatta%20Buona%20Voce"> Assistenza clienti </a>. Puoi anche contattarci inviando una richiesta di dati scrivendoci agli <a href="#Section15"> indirizzi </a> alla fine della presente Informativa sulla privacy e prenderemo in considerazione la tua richiesta in conformità con le leggi applicabili. </p>
<p> Per quanto riguarda le tue preferenze di comunicazione, puoi annullare l'iscrizione alle newsletter seguendo le istruzioni incluse nella newsletter che ricevi, oppure puoi accedere al tuo account e aggiornare le tue opzioni di iscrizione alla newsletter e alla posta elettronica. Se il tuo indirizzo e-mail è cambiato e desideri continuare a ricevere newsletter, dovrai accedere al tuo account, aggiornare le informazioni sull'indirizzo e-mail nel tuo account e iscriverti di nuovo alla newsletter. Occasionalmente potremmo inviare e-mail relative a interruzioni del sito web. </p>
<p> Hai anche delle scelte riguardo ai cookie, come descritto nella nostra <a href="https://www.buonavoce.it/cockie-policy"> Cookie Policy </a>. Modificando le preferenze del browser, potresti essere in grado di scegliere di accettare tutti i cookie, essere avvisato quando un cookie viene impostato o rifiutare tutti i cookie. Se scegli di rifiutare tutti i cookie, alcune parti dei nostri siti Web potrebbero non funzionare correttamente nel tuo caso. </p>
<h2 id = "Section8"> 8. BASI LEGALI DEL TRATTAMENTO </h2>
<p> Raccoglieremo ed elaboreremo i dati personali su di te solo laddove abbiamo basi legali. Le basi legali includono il consenso (se hai fornito il consenso), il contratto (dove l'elaborazione è necessaria per l'esecuzione di un contratto con te (ad esempio, per fornire i Servizi che hai richiesto) e "interessi legittimi". <a href="https://www.buonavoce.it/aboutus/privacy-policy-learn-more">Ulteriori informazioni</a>. </p>
<p> Laddove ci affidiamo al tuo consenso per elaborare i dati personali, hai il diritto di revocare o rifiutare il tuo consenso in qualsiasi momento e laddove ci affidiamo a interessi legittimi, hai il diritto di opporti. Se risiedi nei Paesi designati e hai domande sulla base legale su cui raccogliamo e utilizziamo i tuoi dati personali, contatta il nostro responsabile della protezione dei dati all'indirizzo <a href="mailto:info@buonavoce.it?Subject=Contatta%20Buona%20Voce+Copyright"> info@buonavoce.it </a>. </p>
<h2 id = "Section9"> 9. INFORMAZIONI RELATIVE AI DATI RACCOLTI TRAMITE IL SERVIZIO BUONA VOCE </h2>
<p> Per determinati servizi, raccogliamo informazioni sotto la direzione dei nostri clienti (ad esempio, un'entità che si abbona ai Servizi per l'utilizzo da parte del personale dell'entità). A tale riguardo, agiamo come responsabili del trattamento dei dati ai sensi delle leggi applicabili. Conserveremo e utilizzeremo questi dati personali se necessario per adempiere ai nostri obblighi legali, risolvere controversie e adempiere ai nostri obblighi ai sensi di tali accordi con i responsabili del trattamento dei dati. In questi casi, il titolare del trattamento avrà una propria politica sulla privacy che si applica ai tuoi dati, inclusi i dati personali. Ti invitiamo a leggere le loro politiche sulla privacy per saperne di più sulle loro pratiche sui dati. </p>
<h2 id = "Section10"> 10. SICUREZZA DEI DATI </h2>
<p> L'accesso da parte tua al tuo account è disponibile tramite una password e / o un nome utente univoco selezionato da te. Questa password è crittografata. Ti consigliamo di non divulgare la tua password a nessuno, di cambiarla spesso utilizzando una combinazione di lettere e numeri e di assicurarti di utilizzare un browser web sicuro. Non possiamo essere ritenuti responsabili per attività che derivano dalla tua negligenza per salvaguardare la segretezza della tua password e nome utente. Se condividi un computer con qualcuno, dovresti sempre disconnetterti dal tuo account dopo aver terminato per impedire l'accesso alle tue informazioni da parte degli utenti successivi di quel computer. </p>
<p> Si prega di avvisare l'<a href="mailto:info@buonavoce.it?Subject=Contatta%20Buona%20Voce"> Assistenza clienti </a> il prima possibile se il nome utente o la password sono compromessi. </p>
<p> Sfortunatamente, nessuna trasmissione di dati su Internet o su qualsiasi rete wireless può essere garantita come sicura al 100%. Di conseguenza, mentre ci sforziamo di proteggere i tuoi dati personali, riconosci che: (a) ci sono limitazioni di sicurezza e privacy di Internet che sono al di fuori del nostro controllo; (b) la sicurezza, l'integrità e la privacy di tutte le informazioni e i dati scambiati tra te e noi attraverso questo sito Web non possono essere garantite e non avremo alcuna responsabilità nei tuoi confronti o nei confronti di terzi per perdita, uso improprio, divulgazione o alterazione di tali informazioni ; e (c) tali informazioni e dati potrebbero essere visualizzati o manomessi durante il transito da terzi. </p>
<p> Nell'improbabile caso in cui riteniamo che la sicurezza dei tuoi dati personali sotto il nostro controllo possa essere stata compromessa, proveremo a informarti. Nella misura in cui ci hai fornito il tuo indirizzo e-mail, potremmo informarti tramite e-mail e <u> accetti il ​​nostro utilizzo dell'e-mail come mezzo di tale notifica </u>. Se preferisci che utilizziamo un altro metodo per informarti in questa situazione, contatta l '<a href="mailto:info@buonavoce.it?Subject=Contatta%20Buona%20Voce"> Assistenza clienti </a> con le informazioni di contatto alternative che hai desidera essere utilizzato. </p>
<h2 id = "Section11"> 11. COOKIE E ALTRE TECNOLOGIE </h2>
<p> Quando si utilizzano i nostri siti Web, potremmo utilizzare cookie e altre tecnologie per facilitare e personalizzare l'utilizzo dei nostri Servizi. Scopri di più sulle tue scelte in merito all'uso di queste tecnologie e sulla nostra risposta ai segnali "non tracciare" nella nostra <a href="https://www.buonavoce.it/cockie-policy"> Cookie Policy </a> . </p>
<h2 id = "Section12"> 12. ANALISI MOBILE </h2>
<p> Utilizziamo software di analisi per dispositivi mobili per consentirci di comprendere meglio la funzionalità del nostro software per dispositivi mobili sul tuo telefono. Questo software può registrare informazioni come la frequenza di utilizzo dell'App, gli eventi che si verificano all'interno dell'App, l'utilizzo aggregato, i dati sulle prestazioni e da dove è stata scaricata l'App. Non colleghiamo le informazioni che memorizziamo all'interno del software di analisi ai dati personali che invii all'interno dell'App. </p>
<h2 id = "Section13"> 13. MARKETING DIRETTO </h2>
<p> Al momento non condividiamo i dati personali con terze parti per i loro scopi di marketing diretto senza la tua autorizzazione. </p>
<h2 id = "Section14"> 14. MODIFICHE ALLA PRESENTE POLITICA SULLA PRIVACY </h2>
<p> È nostra prassi pubblicare qualsiasi modifica apportata alla nostra Informativa sulla privacy in questa pagina (con un avviso che la Informativa sulla privacy è stata aggiornata sui siti Web). La data dell'ultima revisione dell'Informativa sulla privacy è indicata nella parte superiore della pagina. Sei responsabile di assicurarti che abbiamo un indirizzo email attivo e consegnabile aggiornato per te e di visitare periodicamente i nostri siti Web e la presente Informativa sulla privacy per verificare eventuali modifiche. </p>
<p> L'uso continuato dei Servizi dopo tali modifiche costituirà l'accettazione dei termini aggiornati dell'Informativa sulla privacy modificata. </p>
<h2 id = "Section15"> 15. COME CI CONTATTATE PER LE DOMANDE? </h2>
<p> In caso di domande o reclami relativi alla presente Informativa sulla privacy, contattare prima l' <a href="mailto:info@buonavoce.it?Subject=Contatta%20Buona%20Voce">Assistenza clienti </a> online. Puoi anche contattarci tramite posta fisica agli indirizzi di seguito.</p>
<p> Buona Voce Formazione sas <br></br> All'attenzione di: Dipartimento Legale (Privacy Policy e User Agreement) <br></br> Centro Direzionale C2 <br></br> 80143 Napoli <br></br> Italia </p>
<p> Se contattarci non risolve il tuo reclamo, hai più opzioni. I residenti nei Paesi designati possono anche avere il diritto di contattare il nostro responsabile della protezione dei dati all'indirizzo <a href="mailto:info@buonavoce.it?Subject=Contatta%20Buona%20Voce"> info@buonavoce.it </a>. </p>
<p> I residenti nei Paesi designati possono avere diritti aggiuntivi in ​​base alle loro leggi. <a href="https://www.buonavoce.it/aboutus/privacy-policy-learn-more"> Ulteriori informazioni </a>.</p>

</div>
			</div>
	
			<div className="grid_4 cms-sidebar">
				
<div className="monopod">
	
	<span className="rightpod">&nbsp;</span>
	
</div><div className="monobottom">&nbsp;</div>
			</div>
		
			</div>
	<Footer></Footer>	
	</div>
  </div>      
    )
  }
//}

export default Privacypolicy;