import React, { Component } from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import { API_URL} from '../../config';
import Header from '../elements/Header/Header';
import Home from '../Home/Home';
import Corso from '../Corso/Corso';
import Aboutus from '../Aboutus/Aboutus';
import Privacypolicy from '../Privacy/Privacypolicy';
import Webpolicy from '../Privacy/Webpolicy';
import Cookiepolicy from '../Privacy/Cookiepolicy';
import Valutazione from '../Valutazione/Valutazione';
import Docenti from '../Docenti/Docenti';
import NotFound from '../elements/NotFound/NotFound';

import Blogpost from '../Blogpost/Blogpost'
import Grafici from '../Grafici/Grafici'
import CorsiDocente from '../CorsiDocente/CorsiDocente'
import Chat from '../Chat/Chat'
import Attestato from '../Attestato/Attestato';
import Clausole from '../Clausole/Clausole';

//import data from '../../data/data.json';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //toggleLogo: true,
      corsi: [],
      docenti: [],
      loading: false
    }
    

  //  this.openNav = this.openNav.bind(this);
  //  this.closeNav = this.closeNav.bind(this);
  }
/*
  componentWillMount() {
    this.setState({
      corsi: data,
    });
  }
*/

//https://api.themoviedb.org/3/movie/553604?api_key=844dba0bfd8f3a4f3799f6130ef9e335&language=it-IT
  componentDidMount() {
    //if (localStorage.getItem(`${this.props.match.params.corsoId}`)) {
    //  const state = JSON.parse(localStorage.getItem(`${this.props.match.params.corsoId}`));
    //  this.setState({ ...state });
    //} else 
    {
      this.setState({ loading: true })
      // First fetch the movie ...
      const endpoint =  `${API_URL}/data/data.json`; //`${API_URL}movie/${this.props.match.params.corsoId}?api_key=${API_KEY}&language=en-US`;
      this.fetchItems(endpoint);
    }
  }

  fetchItems = (endpoint) => {
    fetch(endpoint)
    .then(result => result.json())
    .then(result => {
      console.log
      if (result.status_code) {
        this.setState({ loading: false });
      } else {
        this.setState({ corsi: result }, () => {
          // ... then fetch actors in the setState callback function
          //https://api.themoviedb.org/3/movie/553604/credits?api_key=844dba0bfd8f3a4f3799f6130ef9e335&language=it-IT
          const endpoint = `${API_URL}/data/data.json`; //DettagliCorsi; //`${API_URL}movie/${this.props.match.params.corsoId}/credits?api_key=${API_KEY}`;
          fetch(endpoint)
          .then(result => result.json())

          
          .then(result => {
            //const directors = ""; //result.crew.filter( (member) => member.job === "Director");
            //const directors = result.release_date
            const listaDocenti = result.docente
            this.setState({
              docenti: listaDocenti, //result.docente,
              //directors,
              loading: false
            }
            //, () => {localStorage.setItem(`${this.props.match.params.corsoId}`, JSON.stringify(this.state));}
            )
          })
        })
      }
    })
    .catch(error => console.error('Error:', error))
  }


  /*
  openNav() {
    document.getElementById("myNav").style.width = "100%";
  }

  closeNav() {
    document.getElementById("myNav").style.width = "0%";
  }
*/



render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home corsi={this.state.corsi} />} /> 
            <Route exact path="/corso/:id" element={<Corso
                        corso={this.state.corsi[location.pathname.replace('/corso/', '')]}
                        docente={this.state.docenti} />} />
            <Route exact path="/docenti/" element={<Docenti />} />
            <Route exact path="/aboutus/" element={<Aboutus/>} />
            <Route exact path="/clausole/" element={<Clausole/>} />
            <Route exact path="/chat/" element={<Chat/>} />
            <Route exact path="/privacy-policy/" element={<Privacypolicy/>} />
            <Route exact path="/web-use-policy/" element={<Webpolicy/>} />
            <Route exact path="/cookie-policy/" element={<Cookiepolicy/>} />
            <Route exact path="/valutazione/" element={<Valutazione/>} />
            <Route exact path="/articoli/" element={<Blogpost/>} />
            {/*<Route exact path="/grafici/" component={Grafici} />*/}
            <Route exact path="/docente/:id" element={<CorsiDocente/>} />
            <Route path="/attestati" element={<Attestato />}>
              <Route path=":attestatoId/:user/:corso" element={<Attestato />} />
              <Route path=":attestatoId" element={<Attestato />} />
            </Route>
            <Route element={<NotFound/>} />
          </Routes>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default App;