import React, { Component } from 'react';
import Footer from '../elements/Footer/Footer';


const IMAGE_DEFAULT_URL = './images/corsi/';

const Webpolicy = (props) => {
    return (
<div>
	<div id="about-us-page" role="main" className="page-main" data-tracking-category="about-us-page">
        <div className="container main-content-area">
  
			<div className="main-content">
			<div className="container_16">
<div className="grid_4 copy">
<br></br><br></br>
<h1>WEBSITE USE POLICY</h1>
<p>Ultima Modifica: 28 Dicembre, 2020</p>
</div>
<div id="grid_12" className="grid_12 copy">
<p>Buona Voce Formazione s.a.s, Centro Direzionale C2, 80143 Napoli, Italia (<strong> denominato "Azienda", "noi" o "ci" </strong>), fornisce il sito Web Buonavoce.it all'indirizzo <a href="https://www.buonavoce.it/"> www.buonavoce.it </a> e le app mobili e desktop buonavoce.it (<strong> collettivamente, il "Sito Web" </strong>), a condizione che l'utente rispetti tutti i termini, le condizioni e gli avvisi contenuti o citati nel presente documento ( la <strong> "Norma" </strong>). Inoltre, al momento della sottoscrizione a determinati servizi dal sito Web, gli utenti saranno soggetti a regole aggiuntive applicabili a tali servizi. </p>
<p> UTILIZZANDO IL SITO WEB, DICHIARA DI ACCETTARE LA POLITICA E CHE ACCETTI DI OSSERVARLA. IL TUO RIMEDIO PER L'INSODDISFAZIONE DEL SITO O DEI SUOI ​​CONTENUTI È INTERROMPERE L'UTILIZZO DEL SITO WEB. </p>
<ol class = "privacy_policy_table_of_contents">
<li> <a href="#Section1"> ACCESSO AL SITO WEB </a> </li>
<li> <a href="#Section2"> DIRITTI DI PROPRIETÀ INTELLETTUALE </a> </li>
<li> <a href="#Section3"> I TUOI OBBLIGHI E RAPPRESENTANZE </a> </li>
<li> <a href="#Section4"> CONTRIBUTI DELL'UTENTE </a> </li>
<li> <a href="#Section5"> STANDARD DEI CONTENUTI </a> </li>
<li> <a href="#Section6"> MONITORAGGIO E APPLICAZIONE; RISOLUZIONE </a> </li>
<li> <a href="#Section7"> VIOLAZIONE DEL COPYRIGHT </a> </li>
<li> <a href="#Section8"> AFFIDAMENTO SULLE INFORMAZIONI PUBBLICATE </a> </li>
<li> <a href="#Section9"> PRIVACY </a> </li>
<li> <a href="#Section10"> ABBONAMENTI E ALTRI TERMINI E CONDIZIONI </a> </li>
<li> <a href="#Section11"> LINK DAL SITO WEB </a> </li>
<li> <a href="#Section12"> COLLEGAMENTO AL SITO WEB E FUNZIONI DEI SOCIAL MEDIA </a> </li>
<li> <a href="#Section13"> NESSUNA PROMESSA </a> </li>
<li> <a href="#Section14"> LIMITAZIONE DI RESPONSABILITÀ </a> </li>
<li> <a href="#Section15"> MODIFICHE </a> </li>
<li> <a href="#Section16"> EQUITABLE RELIEF </a> </li>
<li> <a href="#Section17"> LEGGE APPLICABILE E SEDE </a> </li>
<li> <a href="#Section18"> SEPARABILITÀ </a> </li>
<li> <a href="#Section19"> RINUNCIA E MODIFICA </a> </li>
<li> <a href="#Section20"> RESTRIZIONI GEOGRAFICHE </a> </li>
<li> <a href="#Section21"> TRANSAZIONI COMMERCIALI FUTURE </a> </li>
<li> <a href="#Section22"> COMPLETA COMPRENSIONE </a> </li>
<li> <a href="#Section23"> I TUOI COMMENTI E LE TUE PROBLEMI </a> </li>
</ol>
<h2 id = "Section1"> 1. ACCESSO AL SITO WEB </h2>
<p> Sei responsabile di prendere tutte le disposizioni necessarie per avere accesso al sito web. Ci riserviamo il diritto di ritirare o modificare il sito Web e qualsiasi servizio o materiale che forniamo sul sito Web, a nostra esclusiva discrezione e senza preavviso. Non saremo responsabili se, per qualsiasi motivo, tutto o parte del sito Web non è disponibile in qualsiasi momento o per qualsiasi periodo. </p>
<h2 id = "Sezione2"> 2. DIRITTI DI PROPRIETÀ INTELLETTUALE </h2>
<p> (a) Il sito Web e tutti i suoi contenuti, caratteristiche e funzionalità (inclusi ma non limitati a tutte le informazioni, software, testo, display, immagini, video e audio e il design, la selezione e la disposizione degli stessi) sono di proprietà di La Società, i suoi licenziatari o altri fornitori di tale materiale e sono protetti dalle leggi degli Stati Uniti e internazionali su copyright, marchi, brevetti, segreti commerciali e altre proprietà intellettuale o diritti di proprietà. Questa politica ti consente di utilizzare il sito Web solo per uso non commerciale. Nessun diritto, titolo o interesse nel o sul sito Web o qualsiasi contenuto del sito Web viene trasferito all'utente e tutti i diritti non espressamente concessi sono riservati dalla Società. </p>
<p> (b) Non è consentito riprodurre, distribuire, modificare, creare opere derivate, visualizzare pubblicamente, eseguire pubblicamente, ripubblicare, scaricare, archiviare o trasmettere alcun materiale sul sito Web, ad eccezione di quanto segue: (i) È possibile memorizzare i file che vengono automaticamente memorizzati nella cache dal browser Web per scopi di miglioramento della visualizzazione; (ii) se forniamo desktop, dispositivi mobili o altre applicazioni per il download, puoi scaricare una singola copia sul tuo computer o dispositivo mobile esclusivamente per il tuo uso personale e non commerciale, a condizione che accetti di essere vincolato da una licenza per l'utente finale applicabile accordo per tali applicazioni; e (iii) se forniamo funzionalità dei social media in qualsiasi momento, è possibile intraprendere le azioni abilitate da tali funzionalità. </p>
<p> (c) Non è possibile: (i) utilizzare illustrazioni, fotografie, sequenze video o audio o qualsiasi elemento grafico separatamente dal testo di accompagnamento; o (ii) eliminare o alterare qualsiasi avviso di copyright, marchio commerciale o altri diritti di proprietà da copie di materiali dal sito Web; o (iii) accedere o utilizzare per scopi commerciali qualsiasi parte del sito Web o qualsiasi servizio o materiale disponibile tramite il sito Web. </p>
<p> (d) Il nome della Società, il logo della Società e tutti i nomi, i loghi, i nomi di prodotti e servizi, i design e gli slogan correlati sono marchi della Società o delle sue affiliate o licenziatari. Non è possibile utilizzare tali marchi senza previa autorizzazione scritta della Società. Tutti gli altri nomi, loghi, nomi di prodotti e servizi, design e slogan sul sito Web sono marchi dei rispettivi proprietari. </p>
<h2 id = "Section3"> 3. I TUOI OBBLIGHI E RAPPRESENTANZE </h2>
<p> (a) È possibile utilizzare il sito Web solo per scopi leciti e in conformità con questa politica. </p>
<p> (b) Prometti che: (i) hai l'età legale per stipulare un contratto vincolante con la Società; (ii) non utilizzerai il sito web in alcun modo che violi qualsiasi legge o regolamento locale o internazionale applicabile; (iii) non invierai, riceverai consapevolmente, caricherai, scaricherai, utilizzerai o riutilizzerai alcun materiale che non sia conforme agli "Standard di contenuto" (definiti di seguito); (iv) non impersonerai o tenterai di impersonare la Società, un dipendente della Società, un altro utente o qualsiasi altra persona o entità; (v) non farai nulla che possa disabilitare, sovraccaricare, danneggiare o mettere in pericolo il sito web o interferire con l'utilizzo del sito web da parte di qualsiasi persona; (vi) non utilizzerai robot, spider o altri dispositivi, processi o mezzi automatici per accedere al sito Web per scopi illegali o in violazione della presente Politica; (vii) non introdurrete virus, trojan horse, worm, bombe logiche o altro materiale dannoso o tecnologicamente dannoso; e (viii) non farai il co-branding o il frame del sito web o il collegamento ipertestuale ad esso senza l'espresso consenso scritto di un rappresentante autorizzato della Società. </p>
<h2 id = "Section4"> 4. CONTRIBUTI DELL'UTENTE </h2>
<p> (a) Di tanto in tanto potremmo fornire servizi interattivi come funzionalità di "condivisione" (collettivamente, <strong> "Servizi interattivi" </strong>) che consentono agli utenti di pubblicare, inviare, pubblicare, visualizzare o trasmettere ad altre persone (di seguito, <strong> "pubblicazione" </strong>) contenuti o materiali (collettivamente, <strong> "Contributi dell'utente" </strong>) su o attraverso il sito web. Tutti i contributi degli utenti devono essere conformi agli standard sui contenuti stabiliti in questa politica. </p>
<p> (b) Qualsiasi Contributo utente che pubblichi sarà considerato non riservato e non proprietario, nella misura consentita dalla legge. Fornendo un contributo utente, concedi alla Società e ai suoi successori il diritto di utilizzare, riprodurre, modificare, eseguire, visualizzare, distribuire e divulgare in altro modo a terzi tale materiale. Prometti di possedere o controllare tutti i diritti sui e sui Contributi degli utenti e di avere il diritto di concederci tale licenza. Accetti di non avere alcuna pretesa o altro ricorso contro la Società per violazione di qualsiasi diritto di proprietà in relazione ai tuoi Contributi utente. Riconosci e accetti di rinunciare a qualsiasi diritto morale (o simile) che potresti avere in qualsiasi territorio in relazione ai Contributi utente, incluso ma non limitato a, il diritto di essere attribuito come autore dei Contributi utente. </p>
<p> (c) Se fornisci un Contributo utente da trasmettere ad altri utenti del sito Web oa terze parti, accetti che i tuoi Contributi utente siano pubblicati e trasmessi ad altri a tuo rischio. Inoltre, non possiamo controllare le azioni di altri utenti del sito Web o di terze parti con cui puoi scegliere di condividere i tuoi contributi utente. Pertanto, non possiamo e non garantiamo che i tuoi Contributi utente non vengano visualizzati da persone non autorizzate. </p>
<p> (d) I Contributi degli utenti devono essere accurati e rispettare tutte le leggi applicabili nel paese da cui vengono pubblicati. Comprendi e riconosci di essere responsabile per qualsiasi Contributo utente che invii o contribuisci, e tu, non la Società, hai la piena responsabilità per tale contenuto, inclusa la sua legalità, affidabilità, accuratezza e adeguatezza. Non siamo responsabili, o responsabili nei confronti di terze parti, per il contenuto o l'accuratezza di qualsiasi Contributo utente pubblicato da te o da qualsiasi altro utente del sito web. </p>
<h2 id = "Section5"> 5. STANDARD DI CONTENUTO </h2>
<p> Questi standard di contenuto si applicano a tutti i Contributi degli utenti e all'uso dei Servizi interattivi, se offerti. I Contributi degli utenti devono essere integralmente conformi a tutte le leggi e i regolamenti locali e internazionali applicabili. Senza limitare quanto sopra, i Contributi degli utenti non devono: </p>
<ul className="privacy-policy">
<li> Contenere materiale diffamatorio, osceno, indecente, offensivo, offensivo, molesto, violento, odioso, provocatorio o altrimenti discutibile </li>
<li> Promuovere materiale, violenza o discriminazione sessualmente espliciti o pornografici basati su razza, sesso, religione, nazionalità, disabilità, orientamento sessuale o età </li>
<li> Violare brevetti, marchi, segreti commerciali, copyright o altri diritti di proprietà intellettuale o altri diritti di terze parti </li>
<li> Violare i diritti legali (compreso il diritto di pubblicità e privacy) di altri o contenere qualsiasi materiale che possa dar luogo a responsabilità civile o penale ai sensi delle leggi applicabili </li>
<li> Promuovere attività illegali o sostenere, promuovere o assistere qualsiasi atto illegale </li>
<li> Causare fastidio, disagio o ansia inutile o rischiare di turbare, imbarazzare, allarmare o infastidire qualsiasi altra persona </li>
<li> Impersonare qualsiasi persona o travisare la tua identità o affiliazione con qualsiasi persona o organizzazione </li>
<li> Coinvolgere attività commerciali o vendite </li>
<li> È probabile che ingannino o diano l'impressione che provengano da o siano approvati da noi o da qualsiasi altra persona o entità </li>
</ul>
<h2 id = "Section6"> 6. MONITORAGGIO ED ESECUZIONE; RISOLUZIONE </h2>
<p> Abbiamo il diritto, ma non l'obbligo, di rivedere, vagliare o modificare qualsiasi Contributo utente. Accetti che tali contributi non riflettano le opinioni della Società e non siano approvati dalla Società. </p>
<p> Abbiamo il diritto di: (a) intraprendere qualsiasi azione rispetto ai Contributi degli utenti che riteniamo necessaria o appropriata; (b) intraprendere azioni legali appropriate, incluso senza limitazione, rinvio alle forze dell'ordine, per qualsiasi uso illegale o non autorizzato del sito web; (c) interrompere o sospendere l'accesso a tutto o parte del sito Web. </p>
<p> Senza limitare quanto sopra, abbiamo il diritto di cooperare pienamente con le autorità preposte all'applicazione della legge che ci richiedono o ci ordinano di rivelare l'identità o altre informazioni di chiunque pubblichi materiale su o tramite il sito web. Non ci impegniamo a rivedere il materiale prima che sia pubblicato sul sito Web e non possiamo garantire la pronta rimozione di materiale discutibile dopo che è stato pubblicato. Di conseguenza, non ci assumiamo alcuna responsabilità per qualsiasi azione o inazione riguardante trasmissioni, comunicazioni o contenuti forniti da qualsiasi utente o da terzi. Non abbiamo alcuna responsabilità nei confronti di nessuno per l'esecuzione o la mancata esecuzione delle attività descritte in questa sezione. </p>
<h2 id = "Section7"> 7. VIOLAZIONE DEL COPYRIGHT </h2>
<p> Prendiamo sul serio i reclami di violazione del copyright. Risponderemo agli avvisi di presunta violazione del copyright ove appropriato. Se ritieni che qualsiasi materiale accessibile su o dal sito Web violi il tuo copyright, puoi richiedere la rimozione di tali materiali (o l'accesso ad essi) dal sito Web inviando una notifica scritta al nostro agente di copyright (indicato di seguito). In conformità con il Digital Millennium Copyright Act <em> (17 USC § 512) </em> (<strong> "DMCA" </strong>), l'avviso scritto (l '<strong> "Avviso DMCA" </strong> ) deve includere sostanzialmente quanto segue: </p>
<ol className = "dmca">
<li> La tua firma fisica o elettronica. </li>
<li> Identificazione dell'opera protetta da copyright che si ritiene sia stata violata o, se la rivendicazione riguarda più opere sul sito Web, un elenco rappresentativo di tali opere. </li>
<li> Identificazione del materiale che ritieni violi il copyright, in modo sufficientemente preciso da consentirci di individuare tale materiale. </li>
<li> Informazioni adeguate tramite le quali possiamo contattarti (inclusi nome, indirizzo postale, numero di telefono e, se disponibile, indirizzo e-mail). </li>
<li> Una dichiarazione in cui si dichiara in buona fede che l'utilizzo del materiale protetto da copyright non è autorizzato dal proprietario del copyright, dal suo agente o dalla legge. </li>
<li> Una dichiarazione attestante che le informazioni nella notifica scritta sono accurate. </li>
<li> Una dichiarazione, sotto pena di spergiuro, che sei autorizzato ad agire per conto del proprietario del copyright. </li>
</ol>
<p> Il nostro agente di copyright designato per ricevere gli avvisi DMCA è: </p>
<p> Buona Voce Formazione s.a.s. <br></br> ATTN: Agente per il copyright <br></br> Ufficio legale <br></br> Centro Direzionale C2 <br></br> 80143 Napoli, Italia <br></br> Email: <a href="mailto:info@buonavoce.it?Subject=Contatta+Buona+Voce+Copyright"> info@buonavoce.it </a></p>
<p> Se non rispetti tutti i requisiti della sezione 512 (c) (3) del DMCA, la tua notifica DMCA potrebbe non essere efficace. Tieni presente che se dichiari consapevolmente in modo errato che materiale o attività sul sito Web viola il tuo copyright, potresti essere ritenuto responsabile per i danni (inclusi costi e spese legali). </p>
<p> <strong> PROCEDURE DI CONTRO NOTIFICA. </strong> Se ritieni che il materiale che hai pubblicato sul sito web sia stato rimosso o l'accesso ad esso è stato disabilitato per errore o errata identificazione, puoi presentare una contronotifica con noi (un <strong> "Contronotifica" </strong>) inviando una notifica scritta al nostro agente per il copyright (identificato sopra). La Contronotifica deve includere sostanzialmente quanto segue: </p>
<ul class = "privacy-policy">
<li> La tua firma fisica o elettronica </li>
<li> Un'identificazione del materiale che è stato rimosso o al quale l'accesso è stato disabilitato e la posizione in cui appariva il materiale prima che fosse rimosso o l'accesso disabilitato </li>
<li> Informazioni adeguate tramite le quali possiamo contattarti (inclusi nome, indirizzo postale, numero di telefono e, se disponibile, indirizzo e-mail) </li>
<li> Una dichiarazione sotto pena di spergiuro da parte tua che ritieni in buona fede che il materiale identificato sopra sia stato rimosso o disabilitato a seguito di un errore o di un'identificazione errata del materiale da rimuovere o disabilitare </li>
<li> Una dichiarazione che acconsentirà alla giurisdizione del tribunale distrettuale federale locale o, se all'estero, a un organo giudiziario appropriato </li>
</ul>
<p> Il DMCA ci consente di ripristinare il contenuto rimosso se la parte che presenta la notifica DMCA originale non presenta un'azione legale contro di te entro dieci giorni lavorativi dal ricevimento della copia della tua Contronotifica. </p>
<h2 id = "Section8"> 8. FIDUCIA SULLE INFORMAZIONI PUBBLICATE </h2>
<p> Le informazioni presentate su o attraverso il sito Web sono rese disponibili esclusivamente a scopo informativo generale. Non rilasciamo dichiarazioni in merito all'accuratezza, completezza o utilità di queste informazioni. Qualsiasi affidamento che fai su tali informazioni è rigorosamente a tuo rischio. Il sito Web include contenuti forniti da terze parti, inclusi materiali forniti da altri utenti e licenziatari di terze parti. Non siamo responsabili, o responsabili nei confronti dell'utente o di terze parti, per il contenuto o l'accuratezza dei materiali forniti da terze parti. </p>
<h2 id = "Section9"> 9. PRIVACY </h2>
<p> Tutte le informazioni che raccogliamo sul sito web sono soggette alla nostra <a href="https://www.buonavoce.it/privacy-policy"> POLITICA SULLA PRIVACY </a>. Utilizzando il sito Web, acconsenti a tutte le azioni da noi intraprese rispetto alle tue informazioni in conformità con l'Informativa sulla privacy. </p>
<h2 id = "Section10"> 10. ABBONAMENTI E ALTRI TERMINI E CONDIZIONI </h2>
<p> Oltre a questa Politica e alle <a href="https://www.buonavoce.it/privacy-policy"> POLITICA SULLA PRIVACY </a> (<strong> collettivamente, le "Politiche del sito web "</strong>), tutti gli abbonamenti ai prodotti e servizi della Società, sono regolati dal nostro <a href="#"> <strong> <u> TERMINI DI SERVIZIO </u> </strong> </a>, contratto cartaceo o altri termini dichiarati, a seconda del tipo di prodotto / servizio / abbonamento che stai utilizzando. </p>
<h2 id = "Section11"> 11. LINK DAL SITO WEB </h2>
<p> Se il sito Web contiene collegamenti ad altri siti e risorse forniti da terze parti, questi collegamenti vengono forniti solo per comodità dell'utente. Non abbiamo alcun controllo sui contenuti di tali siti o risorse e non ci assumiamo alcuna responsabilità per essi o per eventuali perdite o danni che potrebbero derivare dal loro utilizzo. Se decidi di accedere a siti Web di terze parti collegati al sito Web, lo fai a tuo rischio e soggetto ai termini e alle condizioni di utilizzo di tali siti Web di terze parti. </p>
<h2 id = "Section12"> 12. COLLEGAMENTO AL SITO WEB E ALLE FUNZIONI DEI SOCIAL MEDIA </h2>
<p> È possibile creare un collegamento alla nostra home page, a condizione che lo faccia in modo equo e legale e non danneggi la nostra reputazione o ne tragga vantaggio, ma non è necessario stabilire un collegamento in modo tale da suggerire qualsiasi forma di associazione, approvazione o avallo da parte nostra. Il sito Web può fornire alcune funzionalità dei social media che consentono di: </p>
<ul class = "privacy-policy">
<li> Collegamento da siti Web propri o di terzi a determinati contenuti del sito Web </li>
<li> Inviare e-mail o altre comunicazioni con determinati contenuti o collegamenti a contenuti specifici sul sito Web </li>
<li> Fare in modo che porzioni limitate di contenuto sul sito Web vengano visualizzate o appaiano visualizzate sul proprio sito Web o su determinati siti Web di terze parti </li>
</ul>
<p> È possibile utilizzare queste funzionalità esclusivamente come fornite da noi e unicamente in relazione al contenuto con cui vengono visualizzate, e comunque in conformità con eventuali termini e condizioni aggiuntivi forniti in relazione a tali funzionalità. Fatto salvo quanto sopra, <u> non devi </u>: </p>
<ul class = "privacy-policy">
<li> Stabilisci un link da qualsiasi sito web che non è di tua proprietà </li>
<li> Fare in modo che il sito Web o parti di esso vengano visualizzati o appaiano visualizzati, ad esempio, tramite framing, deep linking o in-line linking, su qualsiasi altro sito </li>
<li> Collegamento a qualsiasi parte del sito Web diversa dalla home page </li>
<li> In caso contrario, intraprendere qualsiasi azione in relazione ai materiali sul sito Web che non sia coerente con qualsiasi altra disposizione di questa politica </li>
</ul>
<p> Il sito Web da cui ci si collega o da cui si rende accessibile un determinato contenuto, deve essere conforme in tutti gli aspetti agli Standard sui contenuti stabiliti nella presente Informativa. Accetti di collaborare con noi per far cessare immediatamente qualsiasi framing o collegamento non autorizzato. Ci riserviamo il diritto di revocare l'autorizzazione al collegamento senza preavviso. Possiamo disabilitare tutte o alcune funzionalità dei social media e qualsiasi collegamento in qualsiasi momento senza preavviso a nostra discrezione. </p>
<h2 id = "Section13"> 13. NESSUNA PROMESSA </h2>
<p> L'UTILIZZO DEL SITO WEB O DEGLI ARTICOLI OTTENUTI TRAMITE IL SITO WEB È A PROPRIO RISCHIO E PERICOLO. IL SITO WEB VIENE FORNITO "COSÌ COM'È" E "COME DISPONIBILE", SENZA PROMESSE DI ALCUN TIPO, ESPLICITE O IMPLICITE. NÉ LA SOCIETÀ NÉ NESSUNA PERSONA ASSOCIATA ALL'AZIENDA PROMETTE ALCUNA PROMESSA, GARANZIA O DICHIARAZIONE IN RELAZIONE ALLA COMPLETEZZA, SICUREZZA, AFFIDABILITÀ, QUALITÀ, PRECISIONE O DISPONIBILITÀ DEL SITO WEB. SENZA LIMITARE QUANTO SOPRA PRECEDENTE, NÉ LA SOCIETÀ NÉ CHIUNQUE ASSOCIATO ALL'AZIENDA PROMESSA, DICHIARA O GARANTISCE CHE IL SITO O GLI ARTICOLI OTTENUTI TRAMITE IL SITO WEB O QUALSIASI PARTE DI ESSO SARÀ ACCURATO, AFFIDABILE, PRIVO DI ERRORI O ININTERROTTO, CHE IL SITO WEB O IL SERVER CHE LI RENDE DISPONIBILI SIANO PRIVI DI VIRUS O ALTRI COMPONENTI DANNOSI, O CHE IL SITO O GLI ELEMENTI OTTENUTI TRAMITE IL SITO WEB SODDISFERANNO ALTRIMENTI LE VOSTRE ESIGENZE O ASPETTATIVE. LA SOCIETÀ NON RICONOSCE ALCUN TIPO DI GARANZIA, ESPLICITA O IMPLICITA, LEGALE O DI ALTRO TIPO, COMPRESA MA NON LIMITATA A QUALSIASI GARANZIA DI COMMERCIABILITÀ, NON VIOLAZIONE E IDONEITÀ A SCOPI PARTICOLARI. </p>
<h2 id = "Section14"> 14. LIMITAZIONE DI RESPONSABILITÀ </h2>
<p> NELLA MISURA MASSIMA CONSENTITA DALLA LEGGE, IN NESSUN CASO LA SOCIETÀ, I SUOI ​​LICENZIATARI, AFFILIATI, DIPENDENTI, AGENTI, FUNZIONARI O AMMINISTRATORI SARANNO RESPONSABILI NEI CONFRONTI DELL'UTENTE O DI QUALSIASI TERZA PARTE PER QUALSIASI PARTE SPECIALE, PUNITIVA, INCIDENTALE, INDIRETTA O CONSEQUENZIALE DANNI DI QUALSIASI TIPO, O QUALSIASI DANNO, COMPRESI, SENZA LIMITAZIONI, QUELLI DERIVANTI DA PERDITA DI UTILIZZO, PERDITA DI DATI O PERDITA DI PROFITTI, CHE SIAMO STATI INFORMATI O NON SULLA POSSIBILITÀ DI TALI DANNI E SU QUALSIASI TEORIA DI RESPONSABILITÀ, DERIVANTE DA O IN CONNESSIONE CON L'USO DEL SITO WEB O DI QUALSIASI SITO WEB A CUI SI RIFERISCE O LINK DAL NOSTRO SITO. SARAI RESPONSABILE DI TUTTI I RECLAMI E DANNI DERIVANTI DALL'USO IMPROPRIO DEL SITO WEB DA PARTE TUO. </p>
<h2 id = "Section15"> 15. MODIFICHE </h2>
<p> Ci riserviamo il diritto, a nostra esclusiva discrezione, di modificare i termini di questa Politica in qualsiasi momento. Qualsiasi modifica diventa effettiva immediatamente dopo la pubblicazione sul sito web. L'uso continuato del sito web costituisce l'accettazione di tutti questi termini e condizioni. </p>
<h2 id = "Section16"> 16. EQUO SOLLIEVO </h2>

<p> L'utente riconosce che una violazione di qualsiasi disposizione di riservatezza o diritti di proprietà di questa Politica può causare alla Società danni irreparabili, per i quali la concessione di un risarcimento non sarebbe un risarcimento adeguato. Di conseguenza, la Società può avviare un'azione per ingiungervi da qualsiasi atto in violazione di tali disposizioni, il cui rimedio sarà cumulativo e non esclusivo, e la Società può richiedere l'entrata di un'ingiunzione ingiungendo qualsiasi violazione o minaccia di violazione di tali disposizioni , oltre a qualsiasi altro sollievo a cui la Società può avere diritto per legge o secondo equità. </p>
<h2 id = "Section17"> 17. LEGGE APPLICABILE E SEDE </h2>
<p> La presente Politica sarà interpretata e regolata dalle leggi dello Stato della California, indipendentemente dai principi di conflitto di leggi. Accetti e accetti che qualsiasi azione legale o procedimento venga portato nei tribunali federali o statali dello Stato della California, Contea di Santa Clara, e rinunci espressamente a qualsiasi obiezione alla giurisdizione personale, sede o foro non conveniente. Se sei un utente del sito web in un paese membro dello Spazio economico europeo (SEE) o Unione europea (UE)), questa politica e il rapporto tra te e noi saranno regolati dalle leggi dello stato membro dell'UE / SEE in cui sei domiciliato. </p>
<h2 id = "Section18"> 18. SEPARABILITÀ </h2>
<p> Se una qualsiasi disposizione della presente Informativa è ritenuta contraria alla legge da un tribunale della giurisdizione competente, tale disposizione deve essere modificata e interpretata in modo da raggiungere al meglio gli obiettivi della disposizione originale nella misura massima consentita dalla legge e dal le restanti disposizioni della presente Politica rimarranno in vigore a tutti gli effetti. </p>
<h2 id = "Section19"> 19. RINUNCIA E MODIFICA </h2>
<p> Se la Società non insiste sul rigoroso adempimento dei vostri obblighi ai sensi di uno qualsiasi di questi termini e condizioni, o se la Società non riesce a esercitare uno dei diritti o rimedi a cui ha diritto in base alla presente Politica, ciò non costituirà rinuncia a tali diritti o rimedi e non ti solleverà dal rispetto di tali obblighi. Nessuna rinuncia da parte della Società a qualsiasi inadempienza costituirà una rinuncia a qualsiasi inadempienza successiva e nessuna rinuncia da parte della Società a uno qualsiasi di questi termini e condizioni sarà efficace a meno che non sia espressamente dichiarato come una rinuncia e vi venga comunicata per iscritto. </p>
<h2 id = "Section20"> 20. RESTRIZIONI GEOGRAFICHE </h2>

<p> Software, funzionalità e / o caratteristiche (collettivamente, <strong> "<u> Funzionalità del servizio </u>" </strong>), che possono essere di volta in volta disponibili su o tramite il Sito Web, sono soggetti ai controlli sulle esportazioni degli Stati Uniti. Nessuna Funzionalità del servizio dal sito Web può essere scaricata o esportata (i) in (o in un residente di) Iraq, Cuba, Libia, Corea del Nord, Iran, Siria o qualsiasi altro paese per cui gli Stati Uniti hanno sottoposto a embargo le merci; o (ii) chiunque sia presente nell'elenco di Specially Designated Nationals del Dipartimento del Tesoro degli Stati Uniti o nella Table of Deny Orders del Dipartimento del Commercio degli Stati Uniti. Scaricando o utilizzando qualsiasi Funzionalità del servizio, dichiari e garantisci di non trovarti in, sotto il controllo di, o di essere cittadino o residente di alcuno di tali paesi o in alcuno di tali elenchi. Sebbene il sito Web possa essere accessibile in tutto il mondo, non garantiamo che i materiali sul sito Web siano appropriati o disponibili per l'uso in luoghi al di fuori degli Stati Uniti e l'accesso da territori in cui i loro contenuti sono illegali è proibito. Coloro che scelgono di accedere al sito Web da altre posizioni lo fanno di propria iniziativa e sono responsabili del rispetto delle leggi locali. Qualsiasi offerta per qualsiasi prodotto, servizio e / o informazione effettuata in relazione ai Servizi è nulla ove proibito. </p>
<h2 id = "Section21"> 21. TRANSAZIONI COMMERCIALI FUTURE </h2>
<p> Mentre continuiamo a sviluppare la nostra attività, potremmo subire un cambio di proprietà come una fusione e / o una vendita di tutte o sostanzialmente tutte le nostre azioni o risorse. In tali transazioni, le informazioni dell'utente sono generalmente una delle risorse aziendali trasferite e, inviandoci dati o contributi (<strong> collettivamente, "Dati" </strong>), accetti che tali Dati possano essere trasferiti a tali parti in queste circostanze. Tuttavia, qualsiasi parte che acquisti le nostre risorse sarà soggetta all'obbligo di mantenere l'integrità delle tue informazioni di identificazione personale. </p>
<h2 id = "Section22"> 22. COMPRENSIONE COMPLETA </h2>
<p> La presente Politica, insieme ai documenti espressamente citati nel presente documento, costituisce l'unico e intero accordo tra l'utente e la Società in relazione al Sito Web e sostituisce tutte le intese, accordi, dichiarazioni e garanzie precedenti e contemporanei, sia scritti che orali, rispetto al sito web. </p>
<h2 id = "Section23"> 23. I TUOI COMMENTI E LE PREOCCUPAZIONI </h2>
<p> Il sito web è gestito da Buona Voce Formazione s.a.s. . In caso di domande, contattaci tramite e-mail all'indirizzo <a href="mailto:info@buonavoce.it?Subject=Contatta+Buona+Voce"> info@buonavoce.it </a>. Se desideri inviarci comunicazioni o servizi di elaborazione, ti preghiamo di inviarci un'e-mail all'indirizzo indicato di seguito. </p>
<p> Buona Voce Formazione s.a.s. <br></br> Attn: Questioni relative al contratto (legale) <br></br> Centro Direzionale C2, <br></br> 80143 Napoli,<br></br> Italia </p>
</div>
</div>
			</div>
		</div>
			
	</div>
	<Footer></Footer>	
</div>      

    )
  }
//}

export default Webpolicy;