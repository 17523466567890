import React, { Component } from 'react';
import Footer from '../elements/Footer/Footer';


const IMAGE_DEFAULT_URL = './images/corsi/';

const Valutazione = (props) => {
    return (
<div>
	<div id="about-us-page" role="main" className="page-main" data-tracking-category="about-us-page">
        <div className="container main-content-area">
  
			<div className="main-content">
			<div className="container_16">
<div className="grid_4 copy">
<br></br><br></br>

<div dangerouslySetInnerHTML={{ __html: "<iframe width='100%' height='480px' src='https://forms.office.com/r/ahetZfwnSc' />"}} />;


<Footer></Footer>	
</div>      
</div>
</div>
			</div>
		</div>
			
	</div>
    )
  }
//}

export default Valutazione;