import React, { Component } from "react";
//import "tabler-react/dist/Tabler.css";

//import { Card, BlogCard, Grid, Button } from "tabler-react";
import Footer from "../elements/Footer/Footer";

class Blogpost extends Component {
  render() {
    return (
      <div>
      {/*<Card>
        <br></br><br></br>
        <Card.Header>
          <Card.Title>Articoli</Card.Title>
        </Card.Header>
        <Grid.Row>
            <Grid.Col>
                <BlogCard
                    title="And this isn't my nose. This is a false one."
                    postUrl="#"
                    description="Look, my liege! The Knights Who Say Ni demand a sacrifice! …Are you suggesting that coconuts migr..."
                    avatarImgSrc="https://www.buonavoce.it/images/docenti/VittoriaCarlino.jpg"
                    authorName="Vittoria Carlino - Prossimi corsi"
                    profileHref="/docente/1"
                    date="Logopedista - Specializzata in Vocologia Artistica"
                    imgSrc="https://tabler.github.io/tabler/demo/photos/david-klaasen-54203-500.jpg"
                    imgAlt="Vittoria Carlino"
                    iconName="heart"
                    iconHref="/docente/1"
                />
            </Grid.Col>
            <Grid.Col>
                <BlogCard
                    title="And this isn't my nose. This is a false one."
                    postUrl="#"
                    description="Look, my liege! The Knights Who Say Ni demand a sacrifice! …Are you suggesting that coconuts migr..."
                    avatarImgSrc="https://tabler.github.io/tabler/demo/faces/female/18.jpg"
                    authorName="Rose Bradley"
                    profileHref="/profile.html"
                    date="3 days ago"
                    imgSrc="https://tabler.github.io/tabler/demo/photos/david-klaasen-54203-500.jpg"
                    imgAlt="Penguin"
                    iconName="heart"
                    iconHref="#"
                />
            </Grid.Col>
            <Grid.Col>
                <BlogCard
                    title="And this isn't my nose. This is a false one."
                    postUrl="#"
                    description="Look, my liege! The Knights Who Say Ni demand a sacrifice! …Are you suggesting that coconuts migr..."
                    avatarImgSrc="https://tabler.github.io/tabler/demo/faces/female/18.jpg"
                    authorName="Rose Bradley"
                    profileHref="/profile.html"
                    date="3 days ago"
                    imgSrc="https://tabler.github.io/tabler/demo/photos/david-klaasen-54203-500.jpg"
                    imgAlt="Penguin"
                    iconName="heart"
                    iconHref="#"
                />
            </Grid.Col>
        </Grid.Row>
        <Grid.Row>
            <Grid.Col>
                <BlogCard
                    title="And this isn't my nose. This is a false one."
                    postUrl="#"
                    description="Look, my liege! The Knights Who Say Ni demand a sacrifice! …Are you suggesting that coconuts migr..."
                    avatarImgSrc="https://www.buonavoce.it/images/docenti/VittoriaCarlino.jpg"
                    authorName="Vittoria Carlino - Prossimi corsi"
                    profileHref="/docente/1"
                    date="Logopedista - Specializzata in Vocologia Artistica"
                    imgSrc="https://tabler.github.io/tabler/demo/photos/david-klaasen-54203-500.jpg"
                    imgAlt="Vittoria Carlino"
                    iconName="heart"
                    iconHref="/docente/1"
                />
            </Grid.Col>
            <Grid.Col>
                <BlogCard
                    title="And this isn't my nose. This is a false one."
                    postUrl="#"
                    description="Look, my liege! The Knights Who Say Ni demand a sacrifice! …Are you suggesting that coconuts migr..."
                    avatarImgSrc="https://tabler.github.io/tabler/demo/faces/female/18.jpg"
                    authorName="Rose Bradley"
                    profileHref="/profile.html"
                    date="3 days ago"
                    imgSrc="https://tabler.github.io/tabler/demo/photos/david-klaasen-54203-500.jpg"
                    imgAlt="Penguin"
                    iconName="heart"
                    iconHref="#"
                />
            </Grid.Col>
            <Grid.Col>
                <BlogCard
                    title="And this isn't my nose. This is a false one."
                    postUrl="#"
                    description="Look, my liege! The Knights Who Say Ni demand a sacrifice! …Are you suggesting that coconuts migr..."
                    avatarImgSrc="https://tabler.github.io/tabler/demo/faces/female/18.jpg"
                    authorName="Rose Bradley"
                    profileHref="/profile.html"
                    date="3 days ago"
                    imgSrc="https://tabler.github.io/tabler/demo/photos/david-klaasen-54203-500.jpg"
                    imgAlt="Penguin"
                    iconName="heart"
                    iconHref="#"
                />
            </Grid.Col>
        </Grid.Row>
        <Footer ></Footer>
      </Card>*/}

<li role="presentation" className="card-cont col-xs-12 col-sm-4 col-md-3">
            <div className="card card-preview rotate-in">
                <div className="card-content">
                    <div className="col-xs-4 col-sm-12 image-column">
                        <a href="https://www.lynda.com/Python-tutorials/Learning-Python/661773-2.html" data-ga-action="click" data-ga-label="card-top" data-ga-value="661773" tabIndex="-1">
                            <div className="thumbnail">

                                <img src="https://via.placeholder.com/480x270.png?text=Corso+480x270" data-appear-top-offset="300" alt="Percorso Formativo" />
                                <div className="watch-options">
                                    <div role="button" className="watch-course ga" data-ga-action="click" data-ga-label="card-watch" data-ga-value="661773" data-preview-text="Iscriviti">
                                        <i className="icon lyndacon play-circle"></i>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-8 col-sm-12 card-meta-data">
                        <a href="https://www.lynda.com/Python-tutorials/Learning-Python/661773-2.html" data-ga-action="click" data-ga-label="card-bottom" data-ga-value="661773">
                            <div className="title-author-info">
                                <h3>Percorso Formativo (Gennaio 2021)</h3>
                                <cite className="meta-author">Dott.ssa Vittoria Carlino</cite>
                            </div>
                        </a>
                        <div className="meta">
                                <span className="meta-duration meta-divider">12h di Formazione (16, 17, 30, 31 Gennaio 2021)</span><br></ br>
								<span className="meta-views meta-divider">Per<strong> Logopedisti e Studenti</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </li>

        <li role="presentation" className="card-cont col-xs-12 col-sm-4 col-md-3">
            <div className="card card-preview rotate-in">
                <div className="card-content">
                    <div className="col-xs-4 col-sm-12 image-column">
                        <a href="https://www.lynda.com/Python-tutorials/Learning-Python/661773-2.html" data-ga-action="click" data-ga-label="card-top" data-ga-value="661773" tabIndex="-1">
                            <div className="thumbnail">

                                <img src="https://via.placeholder.com/480x270.png?text=Corso+480x270" data-appear-top-offset="300" alt="Percorso Formativo" />
                                <div className="watch-options">
                                    <div role="button" className="watch-course ga" data-ga-action="click" data-ga-label="card-watch" data-ga-value="661773" data-preview-text="Iscriviti">
                                        <i className="icon lyndacon play-circle"></i>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-8 col-sm-12 card-meta-data">
                        <a href="https://www.lynda.com/Python-tutorials/Learning-Python/661773-2.html" data-ga-action="click" data-ga-label="card-bottom" data-ga-value="661773">
                            <div className="title-author-info">
                                <h3>Percorso Formativo (Gennaio 2021)</h3>
                                <cite className="meta-author">Dott.ssa Vittoria Carlino</cite>
                            </div>
                        </a>
                        <div className="meta">
                                <span className="meta-duration meta-divider">12h di Formazione (16, 17, 30, 31 Gennaio 2021)</span><br></ br>
								<span className="meta-views meta-divider">Per<strong> Logopedisti e Studenti</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </li>


        <li role="presentation" className="card-cont col-xs-12 col-sm-4 col-md-3">
            <div className="card card-preview rotate-in">
                <div className="card-content">
                    <div className="col-xs-4 col-sm-12 image-column">
                        <a href="https://www.lynda.com/Python-tutorials/Learning-Python/661773-2.html" data-ga-action="click" data-ga-label="card-top" data-ga-value="661773" tabIndex="-1">
                            <div className="thumbnail">

                                <img src="https://via.placeholder.com/480x270.png?text=Corso+480x270" data-appear-top-offset="300" alt="Percorso Formativo" />
                                <div className="watch-options">
                                    <div role="button" className="watch-course ga" data-ga-action="click" data-ga-label="card-watch" data-ga-value="661773" data-preview-text="Iscriviti">
                                        <i className="icon lyndacon play-circle"></i>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-8 col-sm-12 card-meta-data">
                        <a href="https://www.lynda.com/Python-tutorials/Learning-Python/661773-2.html" data-ga-action="click" data-ga-label="card-bottom" data-ga-value="661773">
                            <div className="title-author-info">
                                <h3>Percorso Formativo (Gennaio 2021)</h3>
                                <cite className="meta-author">Dott.ssa Vittoria Carlino</cite>
                            </div>
                        </a>
                        <div className="meta">
                                <span className="meta-duration meta-divider">12h di Formazione (16, 17, 30, 31 Gennaio 2021)</span><br></ br>
								<span className="meta-views meta-divider">Per<strong> Logopedisti e Studenti</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </li>

        <li role="presentation" className="card-cont col-xs-12 col-sm-4 col-md-3">
            <div className="card card-preview rotate-in">
                <div className="card-content">
                    <div className="col-xs-4 col-sm-12 image-column">
                        <a href="https://www.lynda.com/Python-tutorials/Learning-Python/661773-2.html" data-ga-action="click" data-ga-label="card-top" data-ga-value="661773" tabIndex="-1">
                            <div className="thumbnail">

                                <img src="https://via.placeholder.com/480x270.png?text=Corso+480x270" data-appear-top-offset="300" alt="Percorso Formativo" />
                                <div className="watch-options">
                                    <div role="button" className="watch-course ga" data-ga-action="click" data-ga-label="card-watch" data-ga-value="661773" data-preview-text="Iscriviti">
                                        <i className="icon lyndacon play-circle"></i>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-8 col-sm-12 card-meta-data">
                        <a href="https://www.lynda.com/Python-tutorials/Learning-Python/661773-2.html" data-ga-action="click" data-ga-label="card-bottom" data-ga-value="661773">
                            <div className="title-author-info">
                                <h3>Percorso Formativo (Gennaio 2021)</h3>
                                <cite className="meta-author">Dott.ssa Vittoria Carlino</cite>
                            </div>
                        </a>
                        <div className="meta">
                                <span className="meta-duration meta-divider">12h di Formazione (16, 17, 30, 31 Gennaio 2021)</span><br></ br>
								<span className="meta-views meta-divider">Per<strong> Logopedisti e Studenti</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </li>

      </div>
    );
  }
}

export default Blogpost;