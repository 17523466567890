import React, { Component } from 'react';
import { API_URL, API_KEY, IMAGE_BASE_URL, POSTER_SIZE, BACKDROP_SIZE} from '../../config';
import HeroImage from '../elements/HeroImage/HeroImage';
import Footer from '../elements/Footer/Footer';

import './Clausole.css';

const IMAGE_DEFAULT_URL = './images/corsi/';

const Clausole = (props) => {
    return (
      <div id="about-us-page" role="main" className="page-main" data-tracking-category="clausole">
          <div className="container main-content-area">
            <div className="row">
                <div className="col-xs-12 col-md-8">
                    <br></br><br></br><br></br>
                    <h1>Clausole di disdetta corsi presenziali</h1>
                    <p>È possibile effettuare la disdetta e richiedere il rimborso del corso (-10% di costi amministrativi) entro 30 giorni dal giorno d'inizio del corso, 
                    <br></br>oltre tale data, sarà effettuato un rimborso del 50% dell'importo versato.
                    <br></br>Se la disdetta viene richiesta nei 7 giorni precedenti al corso, non sarà possibile ottenere rimborso.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-8">
                    <h2><i>Clausole di disdetta corsi presenziali in caso di positività Covid-19 accertata nei 7 giorni precedenti al corso</i></h2>
                    <p>È possibile effettuare la disdetta e richiedere un rimborso del 50% dell'importo versato previo invio della certificazione di positività accertata nei 7 giorni precedenti al corso.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-8">
                    <h1>Clausole di disdetta corsi online</h1>
                    <p>È possibile effettuare la disdetta e richiedere il rimborso del corso (-10% di costi amministrativi) entro 15 giorni dal giorno d'inizio del corso,
                    <br></br>oltre tale data, sarà effettuato un rimborso del 50% dell'importo versato.
                    <br></br>Se la disdetta viene richiesta nei 7 giorni precedenti al corso, non sarà possibile ottenere rimborso.</p>
                </div>
            </div>

        </div>
        <Footer></Footer>
      </div>
      
    )
  }
//}

export default Clausole;

//{(this.state.currentPage <= this.state.totalPages && !this.state.loading) ?  <LoadMoreBtn text="Mostrane altri" onClick={this.loadMoreItems} />  : null }