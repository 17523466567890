import React, { Component } from 'react';
import { API_URL, API_KEY, IMAGE_BASE_URL, POSTER_SIZE, BACKDROP_SIZE} from '../../config';
import HeroImage from '../elements/HeroImage/HeroImage';
import Footer from '../elements/Footer/Footer';

import './Chat.css';

const IMAGE_DEFAULT_URL = './images/corsi/';

const Chat = (props) => {
    return (
      <div id="about-us-page" role="main" className="page-main" data-tracking-category="about-us-page">
          <div className="container main-content-area">
            <div className="row">
                <div className="col-xs-12 col-md-12">
                <br></br>                
                    <h2>Ciao! Come possiamo aiutarti?&nbsp;&nbsp;<a href="https://forms.office.com/r/yLmzpafaJz" target="feedback">
                    <i class="fa fa-comment"></i>{/*</a>&nbsp;&nbsp;
                    <a href="https://forms.office.com/r/yLmzpafaJz" target="feedback"><i class="fa fa-thumbs-up"></i>
                    <i class="fa fa-thumbs-down fa-flip-horizontal"></i>
                    {/* <i class="fa fa-comment"></i>  */}
                      
                      </a></h2>
                    <iframe src='https://webchat.botframework.com/embed/BuonaVoceWebBot?s=BHni3JyaGYM.0nWSoLl_qbtHuhtN1zD-pUrAiyG2Jg_j0RGKHz3mhx8' className='myIframe'></iframe>
                </div>
                {/*<div className="col-xs-12 col-md-8">
                <br></br><br></br>
                  <img src="https://www.buonavoce.it/BuonaVoceFormazione_VERDE.png"></img>
    </div> */}
            </div>
        </div>
        <Footer></Footer>
      </div>
      
    )
  }
//}

export default Chat;

//{(this.state.currentPage <= this.state.totalPages && !this.state.loading) ?  <LoadMoreBtn text="Mostrane altri" onClick={this.loadMoreItems} />  : null }