import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';
import FontAwesome from 'react-fontawesome';
import './Header.css';

const Header = () => {
  return (
<div role="banner" data-qa="header">
	<nav id="eyebrow" role="navigation">
		<div className="container">
			<div className="row">
				<div className="col-xs-12">
					<button type="button" id="toggle-sidenav" className="visible-xs visible-sm">
						<Link to="">
						<FontAwesome className="fa-home white" name="home" size="3x" /><span className="sr-only">Home</span>
						</Link></button>
					<div className="headnav-logo">
						<a id="nav-logo" rel="home" href="/" className="ga" data-ga-persona="" data-ga-category="header" data-ga-action="click" data-ga-label="lynda.com-logo" data-ga-type="link_click" data-qa="header_logo" itemScope="" itemType="http://schema.org/Organization" itemProp="url">
							<i className="lyndacon lynda-linkedin-logo2 ldc-logo" itemProp="logo" aria-hidden="true"></i>
							<img src="/images/BuonaVoceFormazione_Logo_pub.png" alt="buonavoce-logo" />
							<span className="sr-only">Buona Voce Formazione</span>
						</a>
					</div>
					<div id="submenu-left">
<ul>
	<li id="submenu-library" className="flat-menu submenu-toggle-cont popover-trigger" data-submenu="library-menu">
		<button id="browse-library" className="btn btn-navigation text-uppercase top-menu-item submenu-toggle" aria-haspopup="true" aria-expanded="false" aria-describedby="main-subject-list">
			<a href="/#corsi">Corsi
			&nbsp;<i className="lyndacon arrow-down nav-caret hidden-xs hidden-sm" aria-hidden="true"></i></a>
		</button>
		<a id="browse-subjects" href="/subject/all" aria-hidden="true" tabIndex="-1" className="ga" data-ga-persona="" data-ga-category="header" data-ga-action="click" data-ga-label="library" data-qa="header_subject_menu">All Subjects</a>
		<div id="library-menu" className="popover">
</div>
	</li>
</ul>
{/*													
<form id="submenu-search" className="navbar-form" action="/search" method="get" name="topsrch">
		<div id="live-region" aria-live="polite" className="sr-only"></div>
	<div className="form-group">
		<label for="header-search-field" className="sr-only">Cerca</label>
		<input id="header-search-field" className="autocomplete" type="search" role="combobox" aria-autocomplete="list" aria-owns="header-search-results" aria-expanded="false" aria-activedescendant="" name="q" placeholder="Inserisci il nome del corso di tuo interesse" data-limit="7" data-highlight-strategy="replace" data-results-container="#autocomplete-results" data-no-results-container="#no-results-container" data-one-result-container="#one-result-container" data-many-results-container="#many-results-container" data-loading-icon-container="#site-search-submit" data-url-template="/ajax/search/termAutocomplete?q=<%= val %>&amp;limit=<%= limit %>" data-qa="header_search_field" autocomplete="off" value="" />
		<button className="clear-search lyndacon close-x collapse" type="reset" title="Pulisci ricerca"><span className="sr-only">Pulisci ricerca</span></button>
	</div>
		<button type="submit" id="site-search-submit" className="lyndacon search hidden-xs hidden-sm" data-qa="header_search_cta" title="Cerca"><span className="sr-only">Cerca</span></button>
		<div id="autocomplete-results" className="autocomplete-results-cont autocomplete-dark-theme"><div className="container-fluid">
	<div className="grouped-result row" data-qa="qa_searchResults">
		<ul id="header-search-results" className="autocomplete-results" role="listbox">
</ul>
	</div>
</div>
<div id="no-results-container" className="hidden-message no-results-found">No suggestions found.</div>
<div id="one-result-container" className="hidden-message">One suggestion found. Use up and down keys to navigate.</div>
<div id="many-results-container" className="hidden-message">Multiple suggestions found. Use up and down keys to navigate.</div></div>
</form>

<SearchBar />
*/}
<ul id="submenu-login" className="submenu-login">

							<li className="redirect-links-cont hidden-xs hidden-sm">
								<a href="/chat" target="chatBot" className="am-link tracking top-menu-item top-menu-item-primary ga" data-track-event="cta-click" data-track-button="chatBot" data-ga-category="header" data-ga-action="chatBot-cta-click" data-ga-label="chatBot" data-ga-type="cta_click" data-qa="eyebrow_signup_cta"><i className="fa fa-comments"></i></a>
							</li>

							<li className="redirect-links-cont hidden-xs hidden-sm">
								<a href="https://buonavoce.sharepoint.com/" target="sharepoint" className="am-link tracking top-menu-item top-menu-item-primary ga" data-track-event="cta-click" data-track-button="signup-header" data-ga-category="header" data-ga-action="signup-cta-click" data-ga-label="sign-up" data-ga-type="cta_click" data-qa="eyebrow_signup_cta">Accedi</a>
							</li>
						</ul>
							<div id="sub-nav">
								<ul>
									<li className="sub-nav-links-cont redirect-links-cont">
										<a href="https://bit.ly/buonavoce" target="info" className="am-link redirect-links signup-cta ga" data-ga-persona="" data-ga-category="header" data-ga-action="signup-cta-click" data-ga-label="become-member" data-ga-type="cta_click" data-qa="eyebrow_individual_cta">Richiedi maggiori info sui corsi</a>
									</li>
									<li role="presentation" className="hidden-xs hidden-sm menu-divider">&nbsp;</li>
									<li className="sub-nav-links-cont redirect-links-cont">
									<a href="http://www.sovtraining.com" target="sovtraining" className="am-link redirect-links signup-cta ga" data-ga-persona="" data-ga-category="header" data-ga-action="signup-cta-click" data-ga-label="become-member" data-ga-type="cta_click" data-qa="eyebrow_individual_cta">Progetto SOVTraining</a>
										{/*<Link to="/articoli" className="am-link redirect-links signup-cta ga">Articoli Logopedia</Link>
										/}
										{/*<a href="/articoli" target="info" className="am-link redirect-links signup-cta ga" data-ga-persona="" data-ga-category="header" data-ga-action="signup-cta-click" data-ga-label="become-member" data-ga-type="cta_click" data-qa="eyebrow_individual_cta">Articoli Logopedia</a>*/}
									</li>
								</ul>
								<div className="solutions-cont">
									<span role="heading" id="submenu-solutions" className="section-head">Corsi per:</span>
									<ul id="solutions" aria-labelledby="submenu-solutions">
										<li className="redirect-links-cont sub-nav-links-cont">
											<a href="https://bit.ly/buonavoce" target="info" className="ga tl" data-ga-category="header" data-ga-action="click" data-ga-label="solutions:business" data-ga-type="link_click">Logopedisti</a>
										</li>
										<li className="redirect-links-cont sub-nav-links-cont">
											<a href="https://bit.ly/buonavoce" target="info" className="ga tl" data-ga-category="header" data-ga-action="click" data-ga-label="solutions:higher-ed" data-ga-type="link_click">Cantanti ed Insegnanti di canto</a>
										</li>
										<li className="redirect-links-cont sub-nav-links-cont">
											<a href="https://bit.ly/buonavoce" target="info" className="ga tl" data-ga-category="header" data-ga-action="click" data-ga-label="solutions:government" data-ga-type="link_click">Professionisti Vocali</a>
										</li>
										<li role="presentation" className="hidden-xs hidden-sm menu-divider">|</li>
										<li role="menuitem" className="redirect-links-cont sub-nav-links-cont need-to-train">
											{/*<a className="ga" href="https://learning.linkedin.com/elearning-solutions-contact-us-lynda?trk=ldc_nav_contact&amp;src=ldc-nav" data-ga-category="header" data-ga-action="click" data-ga-label="enterprise:lil:contactus" data-ga-type="link_click" data-qa="eyebrow_multiuser_business_cta">Consiglia ad un amico</a>*/}
											<div className="fb-share-button" data-href="https://www.buonavoce.it" data-layout="button_count" data-size="large"></div>
										</li>
									</ul>
								</div>
							</div>
					</div>
						<button type="button" id="toggle-search"><a href="https://www.buonavoce.it/chat" target="chatbot"><i className="fa fa-comments" aria-hidden="true"></i><span className="sr-only">Chat</span></a></button>
				</div>
			</div>
		</div>
	</nav>
</div>

  )
}

export default Header;


{/*
 <div className="buonavoce-header">
      <div className="buonavoce-header-content">
        <Link to="/">
          <img className="buonavoce-logo" src="./images/BuonaVoceFormazione_logo.png" alt="buonavoce-logo" />
        </Link>
        <img className="buonavoce-tmdb-logo" src="./images/tmdb_logo.png" alt="tmdb-logo" />
      </div>
    </div>
*/}