const feedbackPercorso = {
    "nome": "Percorso Formativo Voce",
    "numeroRecensioni": "62",
    "details": [
        {
            "id": 16,
            "recensione": "Mi è sembrato veramente cucito su misura per noi neolaureate! Perfetto per chi vuole entrare nel mondo della riabilitazione delle disfonie con professionalità ma anche semplicità. Grazie di cuore!!",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
          },
      {
        "id": 1,
        "recensione": "Ringrazio di cuore che esistano corsi simili e professionisti che lavorano con questa passione, capaci di trasmettere ed avere sempre un atteggiamento di accoglienza verso chi si approccia al mondo della voce. Grazie! ",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Dicembre 2021)"
      },
      {
        "id": 2,
        "recensione": "Nonostante conoscessi diversi argomenti trattati, durante il corso questi sono stati ampiamente approfonditi e spiegati secondo un razionale scientifico, seguendo la letteratura. Inoltre i docenti sono stato in grado di spiegare in maniera molto precisa e",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Dicembre 2021)"
      },
      {
        "id": 5,
        "recensione": "il corso è andato oltre ogni aspettativa ....avevo molti dubbi sulla formazione a distanza ma per questo corso mi sono dovuta ricredere",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 3,
        "recensione": "Suggerisco di accorpare le ore del corso in meno giornate",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 4,
        "recensione": "E' stato tutto chiarissimo, ben organizzato anche nella divisione delle tematiche, i riferimenti continui tramite foto e file, professionalità eccellente di tutti! grazie grazie grazie",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 10,
        "recensione": "Tutto perfetto. Forse nelle giornate intere così piene di concetti è difficile mantenere l'attenzione. Mi sono trovata più attiva nelle domeniche in cui c'erano poche ore.",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 11,
        "recensione": "In sole poche giornate ho appreso davvero tanto, ho le idee più chiare sia su valutazione sia su trattamento e molte informazioni in più sull'igiene vocale: ringrazio davvero i docenti per l'impegno.",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 12,
        "recensione": "Corso davvero molto utile. L'esposizione sulla parte pratica è stata chiarissima e davvero fondamentale per chi come me si approccia da poco al mondo della voce. Avrei proseguito con un'altra giornata! Peccato per la parte di spiegazione inerente alla pal",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 13,
        "recensione": "Ho apprezzato molto questo corso perchè mi ha dato strumenti e basi teoriche utili per approcciarmi ai disturbi vocali in modo critico e molto più organizzato di come avrei fatto senza.",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 14,
        "recensione": "Da studentessa posso dire che il corso è stato veramente interessante e soprattutto sia teorico che pratico, cosa che in tantissimi corsi (soprattutto se online), viene a mancare la seconda.",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 15,
        "recensione": "È stato un corso veramente molto valido, mi piace molto l'associazione e trovo i docenti dei corsi proposti sempre molto validi, professionali e preparati, seguirei molti altri corsi in programma se non avessi i limiti di tempo dovuti al mio lavoro, ciò n",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 17,
        "recensione": "molto utile ed esaustivo, l'interattività è stata ottima",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 6,
        "recensione": "nulla da aggiungere",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 7,
        "recensione": "Dedicare più tempo al trattamento",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 8,
        "recensione": "Proporre una formazione dal vivo sulla palpazione e manipolazione laringea",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 9,
        "recensione": "efficace ed interessante",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 18,
        "recensione": "distribuirei meglio le pause soprattutto nelle giornate più intense altrimenti ci si stanca di più e si perde l'attenzione",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 19,
        "recensione": "entusiasmante anche per chi è alle prime armi con la voce e ha voglia di formazione. Docenti sempre pronti a sostenere l'apprendimento con ripetizioni ed esempi. Coinvolgimento di noi partecipanti sempre presente. Lo consiglierò a chiunque volesse approcc",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "oltre ad essere interessante ed utile, è stato ricco di spunti pratici e riflessioni",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 20,
        "recensione": "Da neolaureata, ho apprezzato tantissimo i preziosi consigli che mi sono stati forniti durante il corso. Inoltre, ritengo che tutte le nozioni apprese saranno sicuramente un tesoro da custodire durante la mia carriera lavorativa.",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 21,
        "recensione": "Il corso è stato molto utile e interattivo. Ho apprezzato molto il fatto che ci sia stata la possibilità di fare ipotesi sia sulla parte valutativa sia su quella riabilitativa. Mi ha aiutato molto nel ragionamento clinico",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 22,
        "recensione": "Giusto bilanciamento tra inquadramento teorico e approccio pratico",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 23,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Dicembre 2021)"
      },
      {
        "id": 24,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Dicembre 2021)"
      },
      {
        "id": 25,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Dicembre 2021)"
      },
      {
        "id": 26,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Dicembre 2021)"
      },
      {
        "id": 27,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Dicembre 2021)"
      },
      {
        "id": 28,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Dicembre 2021)"
      },
      {
        "id": 29,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Dicembre 2021)"
      },
      {
        "id": 30,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 31,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 32,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 33,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 34,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 35,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 36,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 37,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Maggio-Giugno 2021)"
      },
      {
        "id": 38,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 39,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 40,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 41,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 42,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 43,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 44,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 45,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 46,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 47,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 48,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 49,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 50,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 51,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Gennaio 2021)"
      },
      {
        "id": 52,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 53,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 54,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "4",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 55,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 56,
        "recensione": "",
        "rating": "4",
        "docenti": "4",
        "organizzazione": "4",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 57,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 58,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 59,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 60,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 61,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      },
      {
        "id": 62,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Percorso Formativo - La Riabilitazione Vocale (Novembre - Dicembre 2020)"
      }
    ],
    "rating": "4.96774193548387",
    "ratingOrganizzazione": "4.95161290322581",
    "ratingDocenti": "4.96774193548387",
    "numeroVotazioni": "62"
  }

    export default feedbackPercorso;