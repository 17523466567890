import React from 'react';
//import './HeroImage.css';

const HeroImage = (props) => {
  return (
<section id="hero-space" className="hero-space-seo-branding xs sm md lg xl" data-lazy-bg-sizes="xs-sm-md-lg-xl">
		<div className="hero-text">
			<div> </div>
		  <h1 className="aq-header"> Buona Voce Formazione</h1>
<div className="subheading first aq">La Formazione Professionale di Qualità</div>

				<p className="cta">
					<a href="https://bit.ly/buonavoce" className="am-link btn btn-primary btn-action ga" data-ga-action="click" data-ga-label="free-trial" data-qa="link-free-trial" target="info">Richiedi info Corsi</a>
				</p>
			<p><a href="https://buonavoce.sharepoint.com/" target="sharepoint">Hai un account? Entra.</a></p>
		</div>
    </section>
  )
}

export default HeroImage;

{/*    
    <div className="buonavoce-heroimage"
  style={{
    background: `url('/images/BuonaVoceFormazione_header.png')`
  }}
>

<div className="buonavoce-heroimage-content">
    <div className="buonavoce-heroimage-text">
      <h1>La Formazione Professionale di Qualità</h1>
      <p className="cta">
					<center><a href="https://bit.ly/buonavoce" className="am-link btn btn-primary btn-action ga" data-ga-action="click" data-ga-label="free-trial" data-qa="link-free-trial" target="info">Richiedi info Corsi</a></center>
				</p>
        <p><center><a href="https://buonavoce.sharepoint.com/" target="sharepoint">Hai un account? Entra.</a></center></p>
    </div>
  </div>
</div>
*/}