import React from 'react';
//import { Link } from 'react-router-dom';
import './Feedback.css';
import quotes from '../../../data/quotes'
// import { useEffect, useState } from "react";

var quote ="";
var author = "";

// const [quote, setQuote] = useState(quotes);
// const [currentQuote,setCurrentQuote] = useState({
// 	quote: "",
// 	author: ""
// 	})

	
// setQuote(quotes);
const generateRandomQuote = (arr) => {
    //get random numbers
    let num = Math.floor(Math.random() * quotes.length)

    let newQuote = quotes[num];

    //update state
      //quote = newQuote.quote;
      //author = newQuote.author;
	//   setCurrentQuote({quote: newQuote.quote, author: newQuote.author})
    
	  quote = newQuote.quote;
      author = newQuote.author;
    

    shuffleQuotes(quotes)

  }

  const shuffleQuotes = (arr) => {
    return arr.sort(function () { return 0.5 - Math.random() });
  }

  //{quote.length <= 300 ?  "testimonial-text" : "testimonial-smalltext"}
  const Quotes = ({quote,author}) =>
  (  
	<div className="testimonial-text">
		<div className="peopleBG"></div>
		<p className="testimonial">
			<span className={quote.length <= 300 ?  "" : "testimonial-smalltext"}><span className="quote-marks"></span>{quote}<span className="quote-marks end"></span></span></p>
		<p className="signature">- {author}</p>
	</div>
	);


const Feedback = () => {
  generateRandomQuote(quotes);

  return (
	<center>
	<div className="whitebg">
		<div id="testimonials"> {/*col-sm-1*/}
			<div className="container">
				<div className="testimonial-wrapper lazy-bg-container">
					<div className="testimonial-holder active">
						{/* <Quotes quote={(this.currentQuote).quote} author={(this.currentQuote).author} /> */}
						<Quotes quote={quote} author={author} />
					</div>
				</div>
			</div>
		</div>

		<div className="container">
			<div className="row">
				<div className="col-xs-12 col-sm-4 feature unlimited">
					<i className="lyndacon infinity"></i>
					<div className="upsell-text">
						<div className="headline">Ottimi Feedback dai Corsisti</div>
						<p>Riceviamo dai nostri corsisti degli ottimi feedback per tutti i corsi che abbiamo organizzato.</p>
					</div>
				</div>
				<div className="col-xs-12 col-sm-4">
				</div>
				<div className="col-xs-12 col-sm-4 feature teachers">
					<i className="lyndacon expert-teachers"></i>
					<div className="upsell-text">
						<div className="headline">Docenti Esperti</div>
						<p>Apprendi dai nostri docenti:<br></br>esperti ed accuratamente selezionati.<br></br>Per una <b>formazione professionale di qualità</b>.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	</center>
)
}

export default Feedback;