import React, { Component } from 'react';
import { API_URL, API_KEY, IMAGE_BASE_URL, POSTER_SIZE, BACKDROP_SIZE} from '../../config';
import HeroImage from '../elements/HeroImage/HeroImage';
import SearchBar from '../elements/SearchBar/SearchBar';
import FourColGrid from '../elements/FourColGrid/FourColGrid';
import MovieThumb from '../elements/MovieThumb/MovieThumb';
import LoadMoreBtn from '../elements/LoadMoreBtn/LoadMoreBtn';
import Spinner from '../elements/Spinner/Spinner';
import Footer from '../elements/Footer/Footer';
import Feedback from '../elements/Feedback/Feedback';
import CookieConsent, { Cookies } from "react-cookie-consent";
//import ElencoCorsi from '../../data/corsiElenco.json';

import VideoAdDiv from '../homeContent/videoAdDiv'

import './Home.css';

const IMAGE_DEFAULT_URL = './images/corsi/';

const Home = (props) => {
    return (
      <div id="home-page" role="main" className="buonavoce-home">
      {//this.state.heroImage ?
        <div>
          <HeroImage 
            //image={`${IMAGE_BASE_URL}${BACKDROP_SIZE}${this.state.heroImage.backdrop_path}`}
            ///title={this.state.heroImage.original_title}
            //text={this.state.heroImage.overview}
            />
          {/* <SearchBar callback={this.searchItems} /> */}
        </div> //: null 
        }
        <div className="buonavoce-home-grid"><a name="corsi"></a>
          <FourColGrid
            header={//this.state.searchTerm ? 'Risultati della Ricerca' : 
            'Prossimi Corsi'}
            subtitle={"Clicca sulla locandina per visualizzare maggiori dettagli sul corso"}
            loading={false}
            //loading={this.state.loading}
            >
            {//this.state.corsi.map ( (element, i) => {
              props.corsi.map ( (element, i) => {
              return <MovieThumb
                        key={i}
                        clickable={true}
                        image={element.poster_path ? `${IMAGE_DEFAULT_URL}${element.poster_path}` : './images/no_image.jpg'}
                        corsoId={element.id}
                        corsoName={element.title}
                        link={element.link}
                        isWebinarGratuito={element.isWebinarGratuito}
                     />
            })}
          </FourColGrid>
          {//this.state.loading ? <Spinner /> : null
          }

        </div>
        {/* <VideoAdDiv /> */}
        <Feedback />
        <CookieConsent buttonText="Accetto" expires={150} declineButtonText="Rifiuto" enableDeclineButton>Informativa: Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per finalità tecniche e, con il tuo consenso, anche per altre finalità (“interazioni e funzionalità semplici”, “miglioramento dell'esperienza”, “misurazione” e “targeting e pubblicità”) come specificato nella cookie policy. Puoi liberamente prestare, rifiutare o revocare il tuo consenso, in qualsiasi momento.</CookieConsent>
         <Footer />
      </div>
    )
  }
//} onDecline={() => {  alert("Devi accettare per poter proseguire la navigazione sul nostro sito");}}

export default Home;

//{(this.state.currentPage <= this.state.totalPages && !this.state.loading) ?  <LoadMoreBtn text="Mostrane altri" onClick={this.loadMoreItems} />  : null }