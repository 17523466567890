const feedbackPalpazione = 
{
    "nome": "Palpazione",
    "numeroRecensioni": "9",
    "details": [
      {
        "id": 1,
        "recensione": "Per la prima volta dopo tanti corsi di formazione, mi sento di dire che il corso era perfetto. Mi è solo spiaciuto non ci fossero più giornate!",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Palpazione e valutazione del sistema fonatorio (29-30 Gennaio 2022)"
      },
      {
        "id": 2,
        "recensione": "Semplicemente fantastico! Grazie per aver organizzato questo corso e per averlo fatto in presenza. Questa formazione mi ha fornito strumenti davvero preziosi per la pratica clinica! ",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Palpazione e valutazione del sistema fonatorio (29-30 Gennaio 2022)"
      },
      {
        "id": 3,
        "recensione": "Corso utilissimo, molto pratico e applicabile nella nostra quotidianità.",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Palpazione e valutazione del sistema fonatorio (29-30 Gennaio 2022)"
      },
      {
        "id": 4,
        "recensione": "Corso esaustivo ed arricchente ",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Palpazione e valutazione del sistema fonatorio (29-30 Gennaio 2022)"
      },
      {
        "id": 5,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Palpazione e valutazione del sistema fonatorio (29-30 Gennaio 2022)"
      },
      {
        "id": 6,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Palpazione e valutazione del sistema fonatorio (29-30 Gennaio 2022)"
      },
      {
        "id": 7,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Palpazione e valutazione del sistema fonatorio (29-30 Gennaio 2022)"
      },
      {
        "id": 8,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Palpazione e valutazione del sistema fonatorio (29-30 Gennaio 2022)"
      },
      {
        "id": 9,
        "recensione": "",
        "rating": "5",
        "docenti": "5",
        "organizzazione": "5",
        "comprensione": "Ho capito la maggior parte degli argomenti trattati",
        "utilita": "Il corso è stato molto interessante ed utile",
        "evento": "Palpazione e valutazione del sistema fonatorio (29-30 Gennaio 2022)"
      }
    ],
    "rating": "5",
    "ratingOrganizzazione": "5",
    "ratingDocenti": "5",
    "numeroVotazioni": "9"
  }

  export default feedbackPalpazione;