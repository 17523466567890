import React, { Component } from 'react';
import Footer from '../elements/Footer/Footer';


const IMAGE_DEFAULT_URL = './images/corsi/';

const Cookiepolicy = (props) => {
    return (
<div>
	<div id="about-us-page" role="main" className="page-main" data-tracking-category="about-us-page">
        <div className="container main-content-area">
  
			<div className="main-content">
			<div className="grid_16 copy">
			<br></br><br></br>
			<h1> Politica sui cookie di Buona Voce Formazione </h1>
				<h2> Buona Voce Formazione utilizza i cookie? </h2>
				<p> Sì. Buona Voce Formazione utilizza i cookie e altre tecnologie per garantire a chiunque utilizzi Buona Voce Formazione la migliore esperienza possibile. I cookie ci aiutano anche a proteggere il tuo account. Continuando a visitare o utilizzare i nostri servizi, acconsenti all'uso di cookie e tecnologie simili per gli scopi descritti in questa informativa. </p>
				<h2> Che cos'è un cookie? </h2>
				<p> Un cookie è un piccolo file inserito nel tuo dispositivo che abilita le caratteristiche e le funzionalità di Buona Voce Formazione. Ad esempio, i cookie ci consentono di identificare il tuo dispositivo, proteggere il tuo accesso a Buona Voce Formazione e ai nostri siti in generale e persino aiutarci a sapere se qualcuno tenta di accedere al tuo account da un dispositivo diverso. I cookie consentono inoltre di condividere facilmente i contenuti su Buona Voce Formazione. </p>
				<h2> Cosa sono le clear GIF? </h2>
				<p> I clear gif sono piccoli elementi grafici con un identificatore univoco, simile nella funzione ai cookie, e vengono utilizzati per tracciare i movimenti online degli utenti Web. A differenza dei cookie, che vengono memorizzati sul disco rigido del computer di un utente, nelle pagine Web sono incorporati clear GIF. </p>
				<h2> Che cos'è un cookie Flash? </h2>
				<p> Gli oggetti condivisi locali, noti anche come "cookie Flash", hanno una funzione simile ai cookie del browser in quanto memorizzano alcune informazioni su di te o sulle tue attività sui nostri siti web. Utilizziamo i cookie Flash in determinate situazioni in cui utilizziamo Flash per fornire alcuni contenuti come clip video o animazioni. Le opzioni all'interno del tuo browser potrebbero non impedire l'impostazione dei cookie Flash. Per gestire i cookie Flash, <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html" target="cookie"> fai clic qui </a>. </p>
				<h2> Quando Buona Voce Formazione inserisce i cookie? </h2>
				<p> Utilizziamo i cookie sui nostri Servizi, inclusi i nostri siti Web e App. Qualsiasi browser che visita i nostri siti Web riceverà cookie da parte nostra. Inoltre, inseriamo cookie nel tuo browser quando visiti siti non Buona Voce Formazione che ospitano i nostri contenuti. </p>
				<h2> Quali tipi di cookie utilizza Buona Voce Formazione? </h2>
				<p> Usiamo due tipi: cookie persistenti e cookie di sessione. Un cookie persistente ci aiuta a riconoscerti come utente esistente, quindi è più facile tornare a Buona Voce Formazione o interagire con i nostri Servizi senza dover eseguire nuovamente l'accesso. Dopo l'accesso, un cookie persistente rimane nel tuo browser e verrà letto da Buona Voce Formazione quando torni a uno dei nostri siti Web o a un sito partner che utilizza i nostri Servizi. I cookie di sessione durano solo per il tempo della sessione (di solito la visita corrente a un sito Web o una sessione del browser). </p>
				<h2> Quale entità Buona Voce Formazione utilizza i cookie? </h2>
				<p> Buona Voce Formazione s.a.s. sarà responsabile dei tuoi dati personali forniti o raccolti da o per i nostri Servizi. </p>
				<h2> A cosa servono i cookie? </h2>
				<p> I cookie possono essere utilizzati per riconoscerti quando visiti Buona Voce Formazione, ricordare le tue preferenze e offrirti un'esperienza personalizzata in linea con le tue impostazioni. I cookie rendono anche le tue interazioni con Buona Voce Formazione più veloci e sicure. Inoltre, i cookie ci consentono di portare pubblicità all'interno e all'esterno dei siti Buona Voce Formazione e di fornire all'utente funzionalità personalizzate. </p>
				<h2> Che cos'è Do Not Track (DNT)? </h2>
				<p> DNT è un concetto che è stato promosso da agenzie di regolamentazione, come la Federal Trade Commission (FTC) degli Stati Uniti, affinché l'industria di Internet sviluppi e implementa un meccanismo per consentire agli utenti di Internet di controllare il monitoraggio delle loro attività online sui siti web utilizzando le impostazioni del browser. Il World Wide Web Consortium (W3C) ha lavorato con gruppi industriali, browser Internet, società tecnologiche e regolatori per sviluppare uno standard tecnologico DNT. Sebbene siano stati compiuti alcuni progressi, è stato lento e nessuno standard è stato adottato fino ad oggi. In quanto tale, Buona Voce Formazione generalmente non risponde ai segnali "non tracciare". </p>
				<h2> Come vengono utilizzati i cookie per scopi pubblicitari? </h2>
				<p> I cookie e altre tecnologie pubblicitarie come beacon, pixel e tag ci aiutano a offrirti annunci pertinenti in modo più efficace. Ci aiutano anche a fornire audit, ricerche e rapporti aggregati per gli inserzionisti, comprendere e migliorare il nostro servizio e sapere quando il contenuto ti è stato mostrato. Nota: poiché il tuo browser web può richiedere pubblicità e beacon direttamente da server di reti pubblicitarie di terze parti, queste reti possono visualizzare, modificare o impostare cookie di terze parti, proprio come se avessi richiesto una pagina web dal loro sito. Gli annunci pubblicati da Buona Voce Formazione possono anche impostare cookie di terze parti. </p>
				<p> Se hai effettuato l'accesso a Buona Voce Formazione o stai navigando in un sito partner di terze parti di Buona Voce Formazione e uno dei nostri cookie sul tuo dispositivo ti identifica, il tuo utilizzo (come il tuo comportamento di navigazione) ei dati di registro (come il tuo Indirizzo IP) sarà da noi associato al tuo account. Utilizziamo anche dati aggregati di terze parti e l'attività di Buona Voce Formazione. </p>
				<p> Se sei un membro di Buona Voce Formazione ma sei disconnesso dal tuo account su un browser, Buona Voce Formazione può comunque continuare a registrare la tua interazione con i nostri servizi su quel browser al fine di generare analisi di utilizzo per i nostri servizi, che analisi noi può condividere in forma aggregata con i nostri clienti pubblicitari. </p>
				<p> A meno che tu non cancelli questi cookie dal tuo browser, potremmo utilizzare queste informazioni per: </p>
				<ul>
				<li> fornire pubblicità più pertinente e basata sugli interessi </li>
				<li> fornire rapporti aggregati sull'attività degli annunci a inserzionisti e siti web che ospitano gli annunci </li>
				<li> aiuta i proprietari di siti web e app a capire come i visitatori interagiscono con i loro siti o app </li>
				<li> rilevare e difendersi da frodi e altri rischi per proteggere utenti e partner </li>
				<li> migliorare i nostri Servizi e altri prodotti </li>
				</ul>
				<p> Non forniamo alcuna informazione personale che raccogliamo agli inserzionisti a meno che tu non ci abbia dato il permesso di farlo. </p>
				<h2> Quali cookie di terze parti utilizza Buona Voce Formazione? </h2>
				<p> La nostra tabella dei cookie elenca alcuni dei cookie di terze parti sui nostri siti. Tieni presente che i nomi di cookie, pixel e altre tecnologie possono cambiare nel tempo. L'uso dei cookie da parte dei nostri partner, affiliati, fornitori di servizi e altre terze parti non è coperto dalla nostra Politica sui cookie e non abbiamo accesso o controllo su questi cookie. </p>
				
				<div dir="ltr" >
				<table classname="table">
				<tbody>
				<tr><th ><span >Domain</span></th><th ><span >Cookie Name</span></th><th ><span >Description</span></th><th ><span >Type</span></th></tr>
				<tr>
				<td><span>&nbsp;Third Party</span></td>
				<td><span>&nbsp;NID, Id, ga, utma, utmz, utmv</span></td>
				<td><span>&nbsp;Google Analytics cookies</span></td>
				<td><span>&nbsp;Persistent</span></td>
				</tr>
				<tr>
				<td><span>&nbsp;Third Party</span></td>
				<td><span>&nbsp;cdn</span></td>
				<td><span>&nbsp;Optimizely cookies to improve user experience</span></td>
				<td><span>&nbsp;Persistent</span></td>
				</tr>
				<tr>
				<td><span>&nbsp;Third Party</span></td>
				<td><span>&nbsp;demdex</span></td>
				<td><span>&nbsp;Adobe Audience Manager cookie</span></td>
				<td><span>&nbsp;Persistent</span></td>
				</tr>
				<tr>
				<td><span>&nbsp;Third Party</span></td>
				<td><span>&nbsp;cfduid</span></td>
				<td><span>&nbsp;WebEngage data segmentation and personalization cookie</span></td>
				<td><span>&nbsp;Persistent</span></td>
				</tr>
				<tr>
				<td><span>&nbsp;Third Party</span></td>
				<td><span>&nbsp;UID, UIDR</span></td>
				<td><span>&nbsp;Scorecard Research analysis cookies</span></td>
				<td><span>&nbsp;Persistent</span></td>
				</tr>
				<tr>
				<td >Buona Voce</td>
				<td><span>&nbsp;bcookie, bscookie</span></td>
				<td><span>&nbsp;Security and anti-fraud cookies</span></td>
				<td><span>&nbsp;Persistent</span></td>
				</tr>
				<tr>
				<td >Buona Voce</td>
				<td><span>&nbsp;LIDC</span></td>
				<td><span>&nbsp;Content delivery optimization cookies</span></td>
				<td><span>&nbsp;Persistent</span></td>
				</tr>
				<tr>
				<td >Buona Voce</td>
				<td><span>&nbsp;Lang, Visit</span></td>
				<td><span>&nbsp;User experience optimization cookies</span></td>
				<td><span>&nbsp;Persistent</span></td>
				</tr>
				</tbody>
				</table>
				</div>



				<h2> Controllo dei cookie </h2>
				<p> La maggior parte dei browser ti consente di controllare i cookie attraverso le loro preferenze di impostazione. Tuttavia, se limiti la capacità dei siti Web di impostare i cookie, potresti peggiorare la tua esperienza utente complessiva, poiché non sarà più personalizzata per te. Potrebbe anche impedirti di salvare impostazioni personalizzate come le informazioni di accesso. </p>
				<h2> Cosa fare se non si desidera che i cookie vengano impostati o che vengano rimossi? </h2>
				<p> Se non desideri ricevere cookie, puoi anche modificare le impostazioni del browser sul tuo computer o altro dispositivo che stai utilizzando per accedere ai nostri servizi. Se utilizzi Buona Voce Formazione senza modificare le impostazioni del browser, presumeremo che accetti di ricevere tutti i cookie sui siti Web Buona Voce Formazione. La maggior parte dei browser fornisce anche funzionalità che consentono di rivedere e cancellare i cookie, inclusi i cookie di Buona Voce Formazione. Tieni presente che il sito Buona Voce Formazione non funzionerà correttamente senza i cookie. </p>
				<p> Per saperne di più sui cookie, incluso come vedere quali cookie sono stati impostati e come gestirli ed eliminarli, visita <a href="https://en.wikipedia.org/wiki/HTTP_cookie" target="cookie"> wikipedia. org </a>, <a href="http://www.allaboutcookies.org/" target="cookie"> www.allaboutcookies.org </a> o <a href = "https://www.aboutcookies.org/" target="cookie" > www.aboutcookies.org </a>.</p>
				<h2> Altre risorse utili </h2>
				<p> Per ulteriori informazioni sull'utilizzo dei cookie da parte degli inserzionisti, sono utili i seguenti collegamenti: </p>
				<ul>
				<li> <a href="http://www.youronlinechoices.eu/" target="cookie"> European Interactive Digital Advertising Alliance (EU) </a> </li>
				<li> <a href="https://www.iab.com/" target="cookie"> Internet Advertising Bureau (USA) </a> </li>
				<li> <a href="https://www.iabeurope.eu/" target="cookie"> Internet Advertising Bureau (UE) </a> </li>
				</ul>
				<p> I produttori di browser forniscono pagine di aiuto relative alla gestione dei cookie nei loro prodotti. Per ulteriori informazioni, vedere di seguito. </p>
				<ul>
				<li> <a href="https://support.google.com/chrome/answer/95647" target="cookie"> Google Chrome </a> </li>
				<li> <a href="https://support.microsoft.com/en-us/help/260971/description-of-cookies" target="cookie"> Internet Explorer </a> </li>
				<li> <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer" target="cookie"> Mozilla Firefox </a> </li>
				<li> <a href="https://support.apple.com/kb/PH5042" target="cookie"> Safari (desktop) </a> </li>
				<li> <a href="https://support.apple.com/HT201265" target="cookie"> Safari (mobile) </a> </li>
				<li> <a href="https://support.google.com/nexus/answer/54068" target="cookie"> Browser Android </a> </li>
				<li> <a href="http://www.opera.com/help" target="cookie"> Opera </a> </li>
				<li> <a href="http://www.opera.com/help/mobile/android#privacy" target="cookie"> Opera Mobile </a> </li>
				</ul>
				<p> Per altri browser, consultare la documentazione fornita dal produttore del browser. </p>
			</div>
			</div>
		</div>
	</div>	
	<Footer></Footer>	
</div>      
    )
  }
//}

export default Cookiepolicy;