import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Footer = () => {
return (
<div id="footer" role="contentinfo" data-qa="footer" data-kiosk="false">
<a className="skip-footer" href="#skip-footer-navigation">Skip navigation</a>
<div id="fat-footer" className="container">
	<div className="row">
		<div className="col-xs-6 col-sm-3 col-md-3">
				<h3>Chi siamo</h3>
                <a className="ga" href="https://www.google.com/maps/place/Buona+Voce+Formazione+S.A.S/@40.8567826,14.2815073,17z/data=!3m1!4b1!4m5!3m4!1s0x133b09120d79c58b:0x39ed2fe38357a70f!8m2!3d40.8567786!4d14.283696" target="maps" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="lil" data-qa="link-lil">Buona Voce Formazione s.a.s.<br />Partita Iva: IT09531791219<br />Centro Direzionale C2<br />80143 Napoli - Italia</a>
				<a className="ga" href="/aboutus/" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="about-us" data-qa="link-about-us">Chi siamo</a>
            	<a className="ga" href="/docenti/" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="careers" data-qa="link-careers">Docenti</a>
				<a className="ga" href="mailto:info@buonavoce.it?Subject=Invia%20il%20tuo%20Progetto%20a%20Buona%20Voce%20Formazione" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="become instructor - non-member" data-qa="link-trainers">Candidati come docente</a>
				<a className="ga" href="/clausole/" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="clausole" data-qa="link-trainers">Clausole corsi</a>
		</div>
		<hr />
        <div className="col-xs-6 col-sm-3 col-md-2">
                <h3>Supporto</h3>
                
				<a className="ga" href="/chat" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="contact-us" data-qa="link-contact"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="headset" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="mr2 child dn dib-ns red4 svg-inline--fa fa-headset fa-w-16 fa-fw"><path fill="currentColor" d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z" class=""></path></svg>Chat</a>
                <a className="ga" href="mailto:info@buonavoce.it?Subject=Contatta%20Buona%20Voce%20Formazione" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="contact-us" data-qa="link-contact"><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="mr2 child dn dib-ns orange4 svg-inline--fa fa-envelope fa-w-16 fa-fw"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" class=""></path></svg>Contattaci via email</a>
				<a className="ga" href="mailto:info@buonavoce.it?Subject=Segnalo%20un%20Problema" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="support" data-qa="link-support"><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="bug" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="mr2 child dn dib-ns red4 svg-inline--fa fa-bug fa-w-18 fa-fw"><path fill="currentColor" d="M536 264h-64v-94.059l40.971-40.971c9.372-9.373 9.372-24.569 0-33.941-9.373-9.372-24.568-9.372-33.941 0L438.059 136H425C425 60.87 364.091 0 289 0c-75.13 0-136 60.909-136 136h-15.059l-40.97-40.971c-9.373-9.372-24.568-9.372-33.941 0-9.373 9.373-9.373 24.569 0 33.941L104 169.941V264H40c-13.255 0-24 10.745-24 24s10.745 24 24 24h64v24c0 29.275 7.91 56.733 21.694 80.365L71.029 471.03c-9.373 9.373-9.373 24.568 0 33.941 9.371 9.372 24.568 9.373 33.941 0l51.029-51.029C184.482 480.046 222.411 496 264 496h48c41.589 0 79.518-15.954 108.001-42.058l51.029 51.029c9.372 9.372 24.568 9.373 33.941 0 9.372-9.373 9.372-24.568 0-33.941l-54.665-54.665C464.09 392.734 472 365.275 472 336v-24h64c13.255 0 24-10.745 24-24s-10.745-24-24-24zM289 48c48.601 0 88 39.399 88 88H201c0-48.601 39.399-88 88-88zm23 400V260c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v188c-61.757 0-112-50.243-112-112V184h272v152c0 61.757-50.243 112-112 112z" class=""></path></svg>Segnala un problema</a>
                <a id="feedback-footer" className="ga" href="/valutazione" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="site-feedback" data-qa="link-site-feedback" data-toggle="modal" data-target="#feedback-footer-modal"><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="paste" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="mr2 child dn dib-ns teal4 svg-inline--fa fa-paste fa-w-14 fa-fw"><path fill="currentColor" d="M433.941 193.941l-51.882-51.882A48 48 0 0 0 348.118 128H320V80c0-26.51-21.49-48-48-48h-61.414C201.582 13.098 182.294 0 160 0s-41.582 13.098-50.586 32H48C21.49 32 0 53.49 0 80v288c0 26.51 21.49 48 48 48h80v48c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48V227.882a48 48 0 0 0-14.059-33.941zm-84.066-16.184l48.368 48.368a6 6 0 0 1 1.757 4.243V240h-64v-64h9.632a6 6 0 0 1 4.243 1.757zM160 38c9.941 0 18 8.059 18 18s-8.059 18-18 18-18-8.059-18-18 8.059-18 18-18zm-32 138v192H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h55.414c9.004 18.902 28.292 32 50.586 32s41.582-13.098 50.586-32H266a6 6 0 0 1 6 6v42h-96c-26.51 0-48 21.49-48 48zm266 288H182a6 6 0 0 1-6-6V182a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v170a6 6 0 0 1-6 6z" class=""></path></svg>Valutazione Corso</a>
				<a className="ga" href="/support/SystemRequirements.aspx" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="system-req" data-qa="link-system-req"></a>
				<a className="ga" href="/promo/activatekey" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="register-act-key" data-qa="link-register-key"></a>
            <a href="/accessibility" data-qa="link-accessibility"></a>
        </div>
		<div className="col-xs-6 col-sm-3 col-md-3">
		</div>
		<hr className="hr-last" />
		<div className="col-md-3">
                <div id="connect">
                    <h3>Connettiti</h3>
                    <ul className="social">
                        <link itemProp="url" href="https://www.buonavoce.it" />
						<li><a itemProp="sameAs" href="https://www.facebook.com/buonavoce" target="facebook" rel="nofollow" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="facebook" className="social-icon ga" data-qa="link-facebook"><i className="lyndacon facebook" aria-hidden="true"></i><span className="sr-only">Follow us on Facebook</span></a></li>
                        <li><a itemProp="sameAs" href="https://www.linkedin.com/in/buonavoce/" target="linkedin" rel="nofollow" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="linkedin" className="social-icon ga" data-qa="link-linkedin"><i className="lyndacon linkedin" aria-hidden="true"></i><span className="sr-only">Follow us on LinkedIn</span></a></li>
						<li><a itemProp="sameAs" href="https://www.instagram.com/buonavoce/" target="instagram" rel="nofollow" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="instagram" className="social-icon ga" data-qa="link-instagram"><i className="fa fa-instagram instagramBottom" aria-hidden="true"></i><span className="sr-only">Follow us on Instagram</span></a></li>
						<li><a itemprop="sameAs" href="https://twitter.com/buonavoceCorsi" target="twitter" rel="nofollow" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="twitter" class="social-icon ga" data-qa="link-twitter"><i className="lyndacon twitter" aria-hidden="true"></i><span class="sr-only">Follow us on Twitter</span></a></li>
						<li><a itemprop="sameAs" href="https://t.me/buonavocebot" target="telegram" rel="nofollow" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="twitter" class="social-icon ga" data-qa="link-twitter"><i className="fa fa-telegram iconMiddle" aria-hidden="true"></i><span class="sr-only">Chat con Bot</span></a></li>
						{/*<li><a itemprop="sameAs" href="https://m.me/buonavoce" target="messenger" rel="nofollow" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="twitter" target="_blank" class="social-icon ga" data-qa="link-twitter"><i class="fab fa-facebook-messenger"></i><span class="sr-only">Chat con Messenger</span></a></li>*/}
						<li><a itemprop="sameAs" href="https://www.buonavoce.it/chat" target="chat" rel="nofollow" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="twitter" class="social-icon ga" data-qa="link-twitter"><i className="fa fa-comments iconMiddle" aria-hidden="true"></i><span class="sr-only">Chat con Bot</span></a></li>
						<li><a itemProp="sameAs" href="https://www.youtube.com/channel/UCs3bRNXNSJRbueZlFSdhlEA" target="youtube" rel="nofollow" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="youtube" className="social-icon ga" data-qa="link-youtube"><i className="fa fa-youtube-play iconMiddle" aria-hidden="true"></i><span className="sr-only">Follow us on youtube</span></a></li>
					</ul>
                </div>
		</div>
	</div>
	<div id="skip-footer-navigation"></div>
</div>    
	<div id="site-links" className="container">
		<div className="row">
			<div className="col-sm-7 col-md-9">
				<ul>
					<li>© 2010-{(new Date().getFullYear())} Buona Voce Formazione</li>
					<li><a className="ga" href="/privacy-policy" data-ga-category="fat-footer" data-ga-action="click" data-ga-label="privacy-policy" data-qa="link-privacy-policy">Privacy policy</a></li>
					<li><a className="ga" href="/web-use-policy" data-ga-category="footer" data-ga-action="click" data-ga-label="web-use-policy" data-qa="link-website-use">Web Use Policy</a></li>
					<li><a className="ga" href="/cookie-policy" data-ga-category="footer" data-ga-action="click" data-ga-label="cookie-policy" data-qa="link-cookie-policy">Cookie Policy</a></li>
				</ul>
			</div>
				<div className="col-sm-5 col-md-3 lower-footer">
					<span id="footer-logo">
						<img src="/images/BuonaVoceFormazione_Logo_pub.png" />
					</span>
					<a href=""><button id="back-to-top"><i className="lyndacon arrow-up" aria-hidden="true"></i><span className="sr-only">Go to top of page</span></button></a>
				</div>
		</div>
	</div>
</div>
)
}

export default Footer;