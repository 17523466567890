const feedbackWebinar = {
    "nome": "Webinar Gratuiti Buona Voce Formazione",
        "numeroRecensioni": "84",
        "details": [
          {
            "id": 1,
            "recensione": "Molto utile. Grazie mille.",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 2,
            "recensione": "tutto veramente realizzato bene",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 3,
            "recensione": "Professionisti seri , disponibili e preparatissimi.",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 4,
            "recensione": "Ben fatto",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 5,
            "recensione": "Ho voluto partecipare proprio perché conoscevo già la preparazione e la ottima capacità espositiva dei relatori. Argomenti vari e complessi quindi non facile da riassumere e schematizzare in modo chiaro e semplice, bravi!",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 6,
            "recensione": "Corso davvero molto bello e ben strutturato; docenti davvero preparati, educati e disponibili. ",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 7,
            "recensione": "Davvero un bel corso, su temi che si trattano purtroppo troppo poco altrove!",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 8,
            "recensione": "tutto molto interessante, ottima organizzazione!",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "La maggior parte delle cose dette al corso le conoscevo già",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 9,
            "recensione": "é stato molto molto interessante",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 10,
            "recensione": "Sempre tutto molto interessante!",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 11,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 12,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 13,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "4",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 14,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 15,
            "recensione": "",
            "rating": "4",
            "docenti": "4",
            "organizzazione": "4",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 16,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 17,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 18,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 19,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 20,
            "recensione": "",
            "rating": "4",
            "docenti": "4",
            "organizzazione": "4",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 21,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 22,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 23,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 24,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 25,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 26,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 27,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 28,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 29,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 30,
            "recensione": "",
            "rating": "5",
            "docenti": "4",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 31,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 32,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 33,
            "recensione": "",
            "rating": "4",
            "docenti": "4",
            "organizzazione": "4",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 34,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 35,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 36,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 37,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 38,
            "recensione": "",
            "rating": "4",
            "docenti": "4",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 39,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 40,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 41,
            "recensione": "Mi sarebbe piaciuto avere maggiori informazioni riguardo il trattamento logopedico dei disturbi della voce, specialmente per quanto riguarda disfonie infantili e periodo della muta vocale.",
            "rating": "4",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 42,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 43,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 44,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 45,
            "recensione": "",
            "rating": "4",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 46,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 47,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 48,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 49,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 50,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 51,
            "recensione": "",
            "rating": "4",
            "docenti": "4",
            "organizzazione": "4",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 52,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 53,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 54,
            "recensione": "",
            "rating": "4",
            "docenti": "5",
            "organizzazione": "4",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 55,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 56,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 57,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 58,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 59,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 60,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 61,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 62,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 63,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 64,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 65,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 66,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 67,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 68,
            "recensione": "",
            "rating": "4",
            "docenti": "5",
            "organizzazione": "4",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 69,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 70,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 71,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 72,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 73,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 74,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 75,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 76,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 77,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 78,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Molto chiaro, gradevolissimi simpatici professionali",
            "evento": ""
          },
          {
            "id": 79,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 80,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "4",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 81,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Ho capito la maggior parte degli argomenti trattati",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 82,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          },
          {
            "id": 83,
            "recensione": "",
            "rating": "4",
            "docenti": "4",
            "organizzazione": "4",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": ""
          },
          {
            "id": 84,
            "recensione": "",
            "rating": "5",
            "docenti": "5",
            "organizzazione": "5",
            "comprensione": "Tutti gli argomenti trattati mi sono stati chiariti durante il corso",
            "utilita": "Il corso è stato molto interessante ed utile",
            "evento": "Focus sulla voce - La voce è il mio strumento (Giornata mondiale della voce 2021)"
          }
        ],
        "rating": "4.88095238095238",
        "ratingOrganizzazione": "4.89285714285714",
        "ratingDocenti": "4.91666666666667",
        "numeroVotazioni": "84"
      }
    export default feedbackWebinar;