// Convert time to hours and minutes
export const calcTime = (time) => {
  const hours = Math.floor(time / 60);
  const mins = time % 60;
  if(mins > 0)
    return `${hours}h ${mins}m`;
  else
    return `${hours}h`;
}

// Convert a number to $ format
export const convertMoney = (money) => {
  var formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  });
  return formatter.format(money);
}

export const strCompare = (str1,str2) => {
  //
   return str1 === str2;
}

export const hasStringValue = (str1) => {
  
  if((str1 !== null) && str1 !== undefined && (str1.length > 0))
   return true;
  else
   return false;
}

export const IntValueOrZero = (val) => {
  
  if(val===undefined) val = 0;

  var formatter = new Intl.NumberFormat('it-IT', {
    minimumFractionDigits: 0,
    maximumSignificantDigits: 2
  });
  
  return formatter.format(val);
}