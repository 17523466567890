import React from 'react';
import { IMAGE_BASE_URL, POSTER_SIZE, BACKDROP_SIZE } from '../../../config';
import FontAwesome from 'react-fontawesome';
import MovieThumb from '../MovieThumb/MovieThumb';
import CourseRating from '../CourseRating/CourseRating';

import { hasStringValue } from '../../../helpers';
import './MovieInfo.css';

const IMAGE_DEFAULT_URL = '/images/corsi/';

const MovieInfo = (props) => {
  return (
    <div className="buonavoce-movieinfo"
      style={{
        background: props.movie.backdrop_path ? `url('${IMAGE_DEFAULT_URL}${props.movie.backdrop_path}')` : '#000'
      }}
    >
      <div className="buonavoce-movieinfo-content">
        <div className="buonavoce-movieinfo-thumb">
          <MovieThumb
            image={props.movie.poster_path ? `${IMAGE_DEFAULT_URL}${props.movie.poster_path}` : './images/no_image.jpg'}
            clickable={false}
            link={props.movie.link}
            isWebinarGratuito={props.movie.isWebinarGratuito}
          />
        </div>
        <div className="buonavoce-movieinfo-text">
          <h1>{props.movie.title}</h1>
          <h3>Descrizione del corso</h3>
          <p>{props.movie.overview}</p>
          {/*<h3>Programma del corso</h3>*/}
          <p>{props.movie.corso_per}</p>
          { props.movie.vote_average > 1 ?
          // <CourseRating vote_average={props.movie.vote_average} 
          //     multiplier="10"
          //     max="100"
          //     label="Valutazione dei corsisti"
          //     optimum="100"
          //     low="40"
          //     high="70"
          // />
          <div className=''>
            <a href="#feedbackCorsisti" className="am-link btn btn-primary btn-action">Leggi le valutazioni dei corsisti</a>
          </div>
          :
          <div></div>
          }

          {/*props.movie.release_date.length > 1 ? <h3>Date</h3> : <h3>Data</h3>}
          {props.movie.release_date.map( (element, i) => {
            return <span key={i} className="buonavoce-director">- {element}<br></br></span>
          })*/}

          <div className="buonavoce-moreinfo">
          { hasStringValue(props.movie.link) && (props.movie.isWebinarGratuito) ? <a href={props.movie.link} className="am-link btn btn-primary btn-action ga" data-ga-action="click" data-ga-label="free-trial" data-qa="link-free-trial" target="info">Iscriviti Gratuitamente al Webinar</a> : <a href="https://bit.ly/buonavoce" className="am-link btn btn-primary btn-action ga" data-ga-action="click" data-ga-label="free-trial" data-qa="link-free-trial" target="info">Richiedi Brochure e disponibilità posti</a>}
          </div>
        </div>
        <div className="buonavoce-info-trailer">
        { hasStringValue(props.movie.trailer) ? <a className="iconeGrandi" href={props.movie.trailer} target="trailer"><FontAwesome className="fa-movie" name="film" size="5x" /></a> : ""}
          <a className="iconeGrandi" href="/" ><FontAwesome className="fa-arrow-circle-o-left white" name="arrow-circle-o-left" size="5x" /></a>
        </div>
      </div>
    </div>
  )
}

export default MovieInfo;