import React from 'react';
import { IMAGE_BASE_URL } from '../../../config';
import './Actor.css';

const IMAGE_DEFAULT_URL = '../images/docenti/';

const Actor = (props) => {

  const POSTER_SIZE = "w154";

  return (
    <div className="buonavoce-actor">
      <img
        src={props.docente.profile_path ? `${IMAGE_DEFAULT_URL}${props.docente.profile_path}` : '../images/no_image.jpg'}
        alt="actorthumb"
      />
      <span className="buonavoce-actor-name">{props.docente.name}</span>
      <span className="buonavoce-actor-character">{props.docente.job}</span>
    </div>
  )
}

export default Actor;