import React, { Component } from "react";
//import "tabler-react/dist/Tabler.css";

//import { Card, BlogCard, Grid, Button, Avatar } from "tabler-react";
import Footer from "../elements/Footer/Footer";

class CorsiDocente extends Component {
  render() {
    return (
        <div>
      {/*<Card>
        <br></br><br></br>
        <Card.Header>
          <Card.Title>Vittoria Carlino</Card.Title>
        </Card.Header>
        <Grid.Row>
            <Grid.Col>
                <BlogCard
                    title="Vittoria Carlino"
                    postUrl="#"
                    description="Look, my liege! The Knights Who Say Ni demand a sacrifice! …Are you suggesting that coconuts migr..."
                    avatarImgSrc="https://www.buonavoce.it/images/docenti/VittoriaCarlino.jpg"
                    authorName="Vittoria Carlino"
                    profileHref="#"
                    date="16 Settembre 2018"
                    imgSrc="https://www.buonavoce.it/images/docenti/VittoriaCarlino.jpg"
                    imgAlt="Vittoria Carlino"
                    iconName="heart"
                    iconHref="#"
                />
            </Grid.Col>
            <Grid.Col>
            <Card>
                <Card.Header>
                    <Card.Title>Focus sulla Voce</Card.Title>
                    <Card.Options>
                    <a href="https://bit.ly/buonavoce" target="info">
                    <Button icon="check" color="succes" size="sm" className="ml-2">
                        Partecipa al Corso
                    </Button>
                    </a>
                    </Card.Options>
                </Card.Header>
                <Card.Body>
                    <img src="http://localhost:3000/images/corsi/Focus_2021_Febbraio.jpg"></img>
                </Card.Body>
                <Card.Footer>
                    <Avatar.List>
                        <Avatar size="xxl" imageURL="/demo/faces/male/9.jpg" />
                        <Avatar size="xxl" imageURL="/demo/faces/female/8.jpg" />
                        <Avatar size="xxl" imageURL="/demo/faces/male/4.jpg" />
                    </Avatar.List>
                </Card.Footer>
                </Card>
            </Grid.Col>
            <Grid.Col>
            <Card>
                <Card.Header>
                    <Card.Title>
                        <a href="https://bit.ly/buonavoce" target="info">
                        <Button icon="check" color="success" size="sm" className="ml-2">
                            Partecipa al Corso
                        </Button>
                        </a>
                    </Card.Title>
                    <Card.Options>
                    <a href="http://localhost:3000/corso/2" target="info">
                    <Button icon="plus" color="yellow" size="sm" className="ml-2">
                        Scheda del Corso
                    </Button>
                    </a>
                    </Card.Options>
                </Card.Header>
                <Card.Body>
                    <img src="http://localhost:3000/images/corsi/Focus_2021_Febbraio.jpg"></img>
                </Card.Body>
                <Card.Footer>
                    <Avatar.List>
                        <Avatar size="xxl" imageURL="/demo/faces/male/9.jpg" />
                        <Avatar size="xxl" imageURL="/demo/faces/female/8.jpg" />
                        <Avatar size="xxl" imageURL="/demo/faces/male/4.jpg" />
                    </Avatar.List>
                </Card.Footer>
                </Card>
            </Grid.Col>
            <Grid.Col>
                <BlogCard
                    title="Corso 3"
                    postUrl="#"
                    description="Look, my liege! The Knights Who Say Ni demand a sacrifice! …Are you suggesting that coconuts migr..."
                    avatarImgSrc="https://tabler.github.io/tabler/demo/faces/female/18.jpg"
                    authorName="Rose Bradley"
                    profileHref="/profile.html"
                    date="15 Maggio 2021"
                    imgSrc="https://tabler.github.io/tabler/demo/photos/david-klaasen-54203-500.jpg"
                    imgAlt="Penguin"
                    iconName="heart"
                    iconHref="#"
                />
                <Avatar.List>
                    <Avatar size="xxl" imageURL="/demo/faces/male/9.jpg" />
                </Avatar.List>
            </Grid.Col>
        </Grid.Row>
        <Footer ></Footer>
      </Card>*/}
      </div>
    );
  }
}

export default CorsiDocente;