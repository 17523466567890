import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './MovieThumb.css';
import { hasStringValue } from '../../../helpers';

const MovieThumb = (props) => {
  return (
    <div className="buonavoce-moviethumb">
      {props.clickable===true ?
        <a href={'/corso/' + props.corsoId} >
            <img src={props.image} alt={props.corsoName} />
          </a>
        :
        <a href='http://bit.ly/buonavoce' target='info'>
          <img src={props.image} alt={props.corsoName} />
          </a>
      }
    </div>
  )
}

MovieThumb.propTypes = {
  image: PropTypes.string,
  corsoId: PropTypes.number,
  corsoName: PropTypes.string,
  link: PropTypes.string,
  isWebinarGratuito: PropTypes.bool
}

export default MovieThumb;



 {/*<Link to={{ pathname: `/corso/${props.corsoId}`, movieName: `${props.movieName}` }}>
 <img src={props.image} alt="moviethumb" />

 { hasStringValue(props.movie.link)? props.movie.link : 'http://bit.ly/buonavoce'}
 
</Link>*/}

