import React, { Component } from 'react';
import Footer from '../elements/Footer/Footer';
import { useParams, useSearchParams } from "react-router-dom";
import queryString from "query-string"
import './Attestato.css';

// import icons
import {FaTwitter, FaFacebook,FaGooglePlus,FaEnvelope,FaLinkedin, FaWhatsapp, FaTelegram }  from 'react-icons/fa';
import {
	EmailShareButton,
	FacebookShareButton,
	LinkedinShareButton,
	TelegramShareButton,
	TwitterShareButton,
	WhatsappShareButton
  } from "react-share";

const IMAGE_DEFAULT_URL = 'https://www.buonavoce.it/attestati/';

const queryParams = queryString.parse(window.location.search);


const Attestato = (props) => {
	//http://localhost:3000/attestati/ef03b5eb-b5e5-4ba3-a948-35015e23f283?user=Sessa%20Marco&corso=SOVTraining%20Online%20(11-12%20Dicembre)
	
	const corso = queryParams.corso;
	const user = queryParams.user;

	let params = useParams();
	let idAttestato = params.attestatoId.replace('.','').replace('/','');
	let imagePath = IMAGE_DEFAULT_URL + idAttestato + ".jpg";
	let isValid = idAttestato.length == 36 ? true : false;

	let title = "Attestato di Partecipazione per il Corso ";
	if(corso !== undefined && user !== undefined)
		title +=  corso + " rilasciato da Buona Voce Formazione a " + user ;
	else
		title +=  " rilasciato da Buona Voce Formazione";

	let shareUrl = IMAGE_DEFAULT_URL + idAttestato;
    return (
<div>
	<div id="about-us-page" role="main" className="page-main" data-tracking-category="about-us-page">
        <div className="container main-content-area">
  
			<div className="main-content">
			<div className="container_16">
<div className="grid_4 copy">
<br></br><br></br>

<div align="center">
		
	{(isValid == true) ? <img src={imagePath} onError={(e)=>{e.target.onerror = null; e.target.src="https://www.buonavoce.it/images/errore-404.jpg?w=750&h=422&a=c"}}/> : <h2>L'Attestato richiesto non è disponibile</h2> }
	{(isValid == true) ?
	<div className="AttestatoButtonStyle"><h2>Condividi su:</h2>
		<FacebookShareButton url={imagePath} quote={title} className="AttestatoButtonStyle">
            <FaFacebook size={64} round className="Facebook" />
        </FacebookShareButton>
		<LinkedinShareButton url={imagePath} className="AttestatoButtonStyle">
            <FaLinkedin size={64} round className="Linkedin"/>
          </LinkedinShareButton>
		  <TwitterShareButton url={imagePath} title={title} className="AttestatoButtonStyle">
            <FaTwitter size={64} round className="Twitter" />
          </TwitterShareButton>
		  <EmailShareButton url={imagePath} subject={title} body="body"className="AttestatoButtonStyle">
            <FaEnvelope size={64} round className="Email" />
          </EmailShareButton>
		  <WhatsappShareButton url={imagePath} title={title} separator=":: " className="AttestatoButtonStyle">
            <FaWhatsapp size={64} round className="WhatsApp"/>
          </WhatsappShareButton>
		  <TelegramShareButton url={imagePath} title={title} className="AttestatoButtonStyle">
            <FaTelegram size={64} round className="Telegram"/>
          </TelegramShareButton>
	</div>
	: <h4>Se hai ricevuto l'email che ti notifica la possibilità di condivisione del tuo attestato online, stiamo procedendo a caricarli sul nostro sito: prova nuovamente tra 10 minuti.</h4>}
	
</div>


<Footer></Footer>	
</div>      
</div>
</div>
			</div>
		</div>
			
	</div>
    )
  }
//}

export default Attestato;